import classNames from 'classnames';
import _, {
  kebabCase,
  keyBy,
  keys,
  last,
  toNumber,
  values,
  uniqBy,
  head,
  isEqual,
  find,
  some,
} from 'lodash';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import InitTable, {Query} from '../../../components/Table/Table';
import {
  AvalaraStandardAssetClass,
  DepreciationSchedule,
  DepreciationScheduleCollection,
  FormGroup,
  IndexTable,
  ReturnAssetMapping,
  ReturnsItem,
  SelectOption,
} from '../../../interface';
import style from './EditAccountMappingTaxability.module.scss';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {
  ASSETS_STATUSES,
  ASSET_STATUS,
  DepreciationScheduleType,
  TOOLTIP_SECTIONS,
} from '../../../enums';
import {InputType} from '../../../components/Table/components/Editor/Editor';
import {
  approveAssetMappings,
  deleteAssetMappings,
  getAssertMappings,
  getAvalaraStandardAssetClasses,
  getDefaultMappings,
  getDepreciationScheduleCollections,
  getDepreciationSchedules,
  getErrorMessage,
  getFormGroups,
  getIndexTableCollections,
  getIndexTables,
  getReturnDetail,
  getReturnForms,
  unapproveAssetMappings,
  updateAssetMappings,
  updateReturnDepreciationScheduleCollection,
  updateReturnForm,
  updateReturnIndexTable,
} from '../../../services';
import {ASSETFILETYPE} from '../../../constants';
import FieldToggleCheckbox from '../../../components/FieldToggleCheckbox/FieldToggleCheckbox';
import FieldSelectTable from '../../../components/FieldSelectTable';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import {updateLoadingObjectByIds} from '../../../utils/reactState';
import TableMenu, {MenuItem} from '../../../components/TableMenu/TableMenu';
import Modal from '../../../components/Modal/Modal';
import ModalContext from '../../../context/ModalContext';
import { formatDate } from '../../../utils/stringUtil';

const {Table, Column, TopBar} = InitTable<ReturnAssetMapping>();

function mapToOptions<T extends {id: number; name: string}>(arr: T[]) {
  return arr.map((item) => ({
    value: item.id,
    label: item.name,
    item: item,
  }));
}

function EditAccountMappingTaxability() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const assetIds = searchParams.getAll('assetId');

  const [selected, setSelected] = useState<ReturnAssetMapping[]>([]);

  const [returnInfo, setReturnInfo] = useState<ReturnsItem>();
  const [items, setItems] = useState<ReturnAssetMapping[]>([]);
  const [total, setTotal] = useState<number>(0);

  const [returnFormOptions, setReturnFormOptions] = useState<SelectOption[]>(
    [],
  );
  const [
    depreciationScheduleCollectionOptions,
    setDepreciationScheduleCollectionOptions,
  ] = useState<SelectOption[]>([]);
  const [indexTableCollectionOptions, setIndexTableCollectionOptions] =
    useState<SelectOption[]>([]);

  const [batchGroupId, setBatchGroupId] = useState<number | null>(null);
  const [batchDepreciationScheduleId, setBatchDepreciationScheduleId] =
    useState<number | null>(null);
  const [batchIndexTableId, setBatchIndexTableId] = useState<number | null>(
    null,
  );
  const [batchTaxable, setBatchTaxable] = useState<boolean>(false);
  const [batchReportable, setBatchReportable] = useState<boolean>(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingReturnDetail, setFetchingReturnDetail] = useState(false);
  const [fetchingReturnForms, setFetchingReturnForms] = useState(false);
  const [
    fetchingDepreciationScheduleCollections,
    setFetchingDepreciationScheduleCollections,
  ] = useState(false);
  const [fetchingIndexTableCollections, setFetchingIndexTableCollections] =
    useState(false);
  const [updatingReturnForm, setUpdatingReturnForm] = useState(false);
  const [
    updatingReturnDepreciationScheduleCollection,
    setUpdatingReturnDepreciationScheduleCollection,
  ] = useState(false);
  const [updatingReturnIndexTable, setUpdatingReturnIndexTable] =
    useState(false);
  const [fetchingStandardAssetClasses, setFetchingStandardAssetClasses] =
    useState(false);
  const [fetchingFormGroups, setFetchingFormGroups] = useState(false);
  const [fetchingDepreciationSchedules, setFetchingDepreciationSchedules] =
    useState(false);
  const [fetchingIndexTables, setFetchingIndexTables] = useState(false);
  const [updatingAssetMappings, setUpdatingAssetMappings] = useState(false);
  const [fetchingDefaultMappings, setFetchingDefaultMappings] = useState(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);

  const colIsEditable = (row: ReturnAssetMapping) => {
    return (
      returnInfo?.status !== 'Approved' &&
      returnInfo?.status !== 'Filed' &&
      row?.status !== 'Approved' &&
      row?.status !== 'Filed'
    );
  };

  const batchEditable = useMemo(() => {
    return !selected.find((item) => !colIsEditable(item));
  }, [selected]);

  const aggregateSelectedAssets = () => {
    const uniqueFormGroupIds = uniqBy(selected, 'formGroupId').map(
      (item) => item?.formGroupId,
    );
    const uniqueDepreciationScheduleIds = uniqBy(
      selected,
      'depreciationScheduleId',
    ).map((item) => item?.depreciationScheduleId);
    const uniqueIndexTableIds = uniqBy(selected, 'indexTableId').map(
      (item) => item?.indexTableId,
    );
    const uniTaxables = uniqBy(selected, 'taxable').map(
      (item) => item?.taxable,
    );
    const uniReportables = uniqBy(selected, 'reportable').map(
      (item) => item?.reportable,
    );
    return {
      batchGroupId:
        uniqueFormGroupIds.length > 1
          ? null
          : (head(uniqueFormGroupIds) as number),
      batchDepreciationScheduleId:
        uniqueDepreciationScheduleIds.length > 1
          ? null
          : (head(uniqueDepreciationScheduleIds) as number),
      batchIndexTableId:
        uniqueIndexTableIds.length > 1
          ? null
          : (head(uniqueIndexTableIds) as number),
      batchTaxable: uniTaxables.length > 1 ? false : head(uniTaxables) ?? false,
      batchReportable:
        uniReportables.length > 1 ? false : head(uniReportables) ?? false,
    };
  };

  const batchSaveDisabled = useMemo(() => {
    if (selected.length > 0) {
      const batchValues = aggregateSelectedAssets();
      return isEqual(batchValues, {
        batchGroupId,
        batchDepreciationScheduleId,
        batchIndexTableId,
        batchTaxable,
        batchReportable,
      });
    } else {
      return true;
    }
  }, [
    selected,
    batchGroupId,
    batchDepreciationScheduleId,
    batchIndexTableId,
    batchTaxable,
    batchReportable,
  ]);

  const fetchData = (query?: Query) => {
    if (params.returnId) {
      setFetchingData(true);
      setSelected([]);
      getAssertMappings(params.returnId, {
        page: query?.pagination?.page ?? 1,
        perPage: query?.pagination?.pageSize ?? 10,
        sortBy: last(keys(query?.order)),
        sortDirection: last(values(query?.order)),
        statuses: (query?.filter?.status as string[])?.join(',') ?? '',
        types: (query?.filter?.["asset.type"] as string[])?.join(',') ?? '',
        formGroupIds: (query?.filter?.formGroupId as number[])?.join(',') ?? '',
        depreciationScheduleIds:
          (query?.filter?.depreciationScheduleId as number[])?.join(
            ',',
          ) ?? '',
        indexTableIds:
          (query?.filter?.indexTableCollectionId as number[])?.join(',') ?? '',
        taxables: (query?.filter?.taxable as boolean[])?.join(',') ?? '',
        reportables: (query?.filter?.reportable as boolean[])?.join(',') ?? '',
        additionStatuses: (query?.filter?.["asset.isAddition"] as boolean[])?.join(',') ?? '',
        disposedStatuses: (query?.filter?.["asset.disposed"] as boolean[])?.join(',') ?? '',
        acquisitionDateRange: query?.filter?.["asset.acquisitionDate"] as string[],
        commonClassIds: (query?.filter?.commonClassId as number[])?.join(',') ?? '',
        ids: assetIds.join(',') ?? '',
      })
        .then((res) => {
          setItems(res.data.items);
          setTotal(res.data.total);
          setFetchingData(false);
        })
        .catch(() => {
          setFetchingData(false);
        });
    }
  };

  useEffect(() => {
    if (selected.length > 0) {
      const batchValues = aggregateSelectedAssets();
      setBatchGroupId(batchValues.batchGroupId);
      setBatchDepreciationScheduleId(batchValues.batchDepreciationScheduleId);
      setBatchIndexTableId(batchValues.batchIndexTableId);
      setBatchTaxable(batchValues.batchTaxable);
      setBatchReportable(batchValues.batchReportable);
    }
  }, [selected]);

  useEffect(() => {
    if (params.returnId) {
      setFetchingReturnDetail(true);
      getReturnDetail(params.returnId)
        .then((res) => {
          setReturnInfo(res.data);
          setFetchingReturnDetail(false);
        })
        .catch(() => {
          setFetchingReturnDetail(false);
        });
    }
  }, [params.returnId]);

  useEffect(() => {
    if (returnInfo) {
      setFetchingReturnForms(true);
      getReturnForms({
        page: 0,
        stateId: returnInfo.assessorAccountData.property.address.stateId,
        taxYear: returnInfo.taxYear,
      })
        .then((res) => {
          setFetchingReturnForms(false);
          setReturnFormOptions(mapToOptions(res.data.items));
        })
        .catch(() => {
          setFetchingReturnForms(false);
        });
    }
  }, [returnInfo?.assessorAccountData.property.address.stateId, returnInfo?.taxYear]);

  useEffect(() => {
    if (returnInfo) {
      setFetchingDepreciationScheduleCollections(true);
      getDepreciationScheduleCollections({
        page: 0,
        stateIds: returnInfo.assessorAccountData.property.address.stateId?.toString() ?? '',
        taxYear: returnInfo.taxYear
      })
        .then((res) => {
          setDepreciationScheduleCollectionOptions(
            mapToOptions(res.data.items),
          );
          setFetchingDepreciationScheduleCollections(false);
        })
        .catch(() => {
          setFetchingDepreciationScheduleCollections(false);
        });

      setFetchingIndexTableCollections(true);
      getIndexTableCollections({
        page: 0,
        stateIds: returnInfo.assessorAccountData.property.address.stateId?.toString() ?? '',
        taxYear: returnInfo.taxYear
      })
        .then((res) => {
          setIndexTableCollectionOptions(mapToOptions(res.data.items));
          setFetchingIndexTableCollections(false);
        })
        .catch(() => {
          setFetchingIndexTableCollections(false);
        });
    }
  }, [returnInfo?.assessorAccountData.property.address.stateId]);

  const selectedCompositeCollection = useMemo(() => {
    const selectedItem: DepreciationScheduleCollection = find(
      depreciationScheduleCollectionOptions,
      (x) => x.value === returnInfo?.depreciationScheduleCollectionId,
    )?.item;

    return selectedItem?.type === DepreciationScheduleType.Composite;
  }, [
    depreciationScheduleCollectionOptions,
    returnInfo?.depreciationScheduleCollectionId,
  ]);

  const [standardAssetClassOptions, setStandardAssetClassOptions] = useState<
    SelectOption[]
  >([]);
  const [formGroupOptions, setFormGroupOptions] = useState<SelectOption[]>([]);
  const [depreciationScheduleOptions, setDepreciationScheduleOptions] =
    useState<SelectOption[]>([]);
  const [indexTableOptions, setIndexTableOptions] = useState<SelectOption[]>(
    [],
  );
  const [deletingAssetMappings, setDeletingAssetMappings] = useState<{
    [key: number]: boolean;
  }>({});
  const [unapprovingAssetMappings, setUnapprovingAssetMappings] = useState<{
    [key: number]: boolean;
  }>({});

  useEffect(() => {
    setFetchingStandardAssetClasses(true);
    getAvalaraStandardAssetClasses()
      .then((res) => {
        setFetchingStandardAssetClasses(false);
        setStandardAssetClassOptions(
          res.data.map((item: AvalaraStandardAssetClass) => ({
            label: item.name,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        setFetchingStandardAssetClasses(false);
      });
  }, []);

  useEffect(() => {
    if (returnInfo?.formId) {
      setFetchingFormGroups(true);
      getFormGroups({
        formId: returnInfo.formId,
      })
        .then((res) => {
          setFormGroupOptions(
            res.data.items.map((item: FormGroup) => ({
              label: item.name,
              value: item.id,
            })),
          );
          setFetchingFormGroups(false);
        })
        .catch(() => {
          setFetchingFormGroups(false);
        });
    }
  }, [returnInfo?.formId]);

  useEffect(() => {
    if (returnInfo?.depreciationScheduleCollectionId) {
      setFetchingDepreciationSchedules(true);
      getDepreciationSchedules({
        depreciationScheduleCollectionId:
          returnInfo.depreciationScheduleCollectionId,
      })
        .then((res) => {
          setFetchingDepreciationSchedules(false);
          setDepreciationScheduleOptions(
            res.data.items.map((item: DepreciationSchedule) => ({
              label: item.name,
              value: item.id,
            })),
          );
        })
        .catch(() => {
          setFetchingDepreciationSchedules(false);
        });
    }
  }, [returnInfo?.depreciationScheduleCollectionId]);

  useEffect(() => {
    if (returnInfo?.indexTableCollectionId) {
      setFetchingIndexTables(true);
      getIndexTables({
        indexTableCollectionId: returnInfo.indexTableCollectionId,
      })
        .then((res) => {
          setIndexTableOptions(
            res.data.items.map((item: IndexTable) => ({
              label: item.name,
              value: item.id,
            })),
          );
          setFetchingIndexTables(false);
        })
        .catch(() => {
          setFetchingIndexTables(false);
        });
    }
  }, [returnInfo?.indexTableCollectionId]);

  const breadcrumb = [
    {
      value: 'Returns',
      href: '/returns?tab=Approve+Assets',
    },
    {
      value: 'Edit Account Mapping & Taxability',
    },
  ];

  const clickBack = () => {
    navigate(`/returns?tab=Approve+Assets`);
  };

  const renderPropertyInfoField = (
    label: string,
    value: string | undefined,
  ) => {
    return (
      <div className={style['info-field']}>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.AccountInfo}
          tooltipKey={label}
        >
          <div>
            <div className={style['info-field-label']}>{label}</div>
            <div className={style['info-field-value']}>{value}</div>
          </div>
        </TooltipWrapper>
      </div>
    );
  };

  const saveChanges = async (
    ids: number[],
    payload: {
      commonClassId?: number;
      taxable?: boolean | null;
      formGroupId?: number | null;
      depreciationScheduleId?: number | null;
      indexTableId?: number | null;
      reportable?: boolean;
      isAddition?: boolean;
      disposed?: boolean;
    },
  ) => {
    setUpdatingAssetMappings(true);
    return updateAssetMappings(toNumber(params.returnId), ids, payload)
      .then((res) => {
        const errorMessage = getErrorMessage(res);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);
        } else {
          toastRef.current?.showSuccessToast('Save successfully');
          const newItems = [...items];
          for (const updatedItem of res.data) {
            const index = newItems.findIndex(
              (item) => item.id === updatedItem.id,
            );
            newItems[index] = {
              ...newItems[index],
              ...updatedItem,
            };
          }
          setItems(newItems);
          const selectedById = keyBy(selected, 'id');
          setSelected(newItems.filter((x) => x.id in selectedById));
        }
        setUpdatingAssetMappings(false);
      })
      .catch((e) => {
        toastRef.current?.showErrorToast(getErrorMessage(e));
        setUpdatingAssetMappings(false);
      });
  };

  const clickSaveItem = (
    id: number,
    payload: {
      taxable?: boolean | null;
      formGroupId?: number | null;
      depreciationScheduleId?: number | null;
      indexTableId?: number | null;
      reportable?: boolean;
      isAddition?: boolean;
      disposed?: boolean;
    },
  ) => {
    saveChanges([id], payload);
  };

  const clickSaveBatch = () => {
    const newBatchValues = {
      batchTaxable,
      batchGroupId,
      batchDepreciationScheduleId,
      batchIndexTableId,
      batchReportable,
    };
    const payload = {
      taxable: newBatchValues.batchTaxable,
      formGroupId: newBatchValues.batchGroupId,
      depreciationScheduleId: newBatchValues.batchDepreciationScheduleId,
      indexTableId: newBatchValues.batchIndexTableId,
      reportable: newBatchValues.batchReportable,
    };
    saveChanges(
      selected.map((item) => item.id),
      payload,
    );
  };

  const clickCancelBatch = () => {
    const batchValues = aggregateSelectedAssets();
    setBatchGroupId(batchValues.batchGroupId);
    setBatchDepreciationScheduleId(batchValues.batchDepreciationScheduleId);
    setBatchIndexTableId(batchValues.batchIndexTableId);
    setBatchTaxable(batchValues.batchTaxable);
    setBatchReportable(batchValues.batchReportable);
  };

  const [approvingAssetMappings, setApprovingAssetMappings] = useState<{
    [key: number]: boolean;
  }>({});

  const selectedIds = useMemo(
    () => (_.compact(selected).length ? selected.map((item) => item?.id) : []),
    [selected],
  );

  const canApproveSelected = useMemo(
    () =>
      selected.length > 0 &&
      !some(selected, (x) =>
        [
          ASSET_STATUS.Approved,
          ASSET_STATUS.Filed,
          ASSET_STATUS.Mapping,
        ].includes(x?.status as ASSET_STATUS),
      ),
    [selected],
  );

  const updateApprovingAssetMappings = (ids: number[], deleting: boolean) => {
    updateLoadingObjectByIds(
      ids,
      deleting,
      approvingAssetMappings,
      setApprovingAssetMappings,
    );
  };

  const updateDeletingAssetMapping = (ids: number[], deleting: boolean) => {
    updateLoadingObjectByIds(
      ids,
      deleting,
      deletingAssetMappings,
      setDeletingAssetMappings,
    );
  };

  const [deletingAsset, setDeletingAsset] = useState(false);
  const isApprovingAssetMappings = (ids: number[]) => {
    return ids.length > 0 && _.every(ids, (id) => !!approvingAssetMappings[id]);
  };

  const getMenuItems = ({
    status,
    id,
    asset,
  }: ReturnAssetMapping): MenuItem[][] => {
    let navigator = '';
    switch (asset.type) {
      case ASSETFILETYPE[1]:
        navigator = `/assets/editInventorySetup/${asset.id}`;
        break;
      case ASSETFILETYPE[2]:
        navigator = `/assets/editVehicleSetup/${asset.id}`;
        break;
      case ASSETFILETYPE[3]:
        navigator = `/assets/editLeasedVehicleSetup/${asset.id}`;
        break;
      case ASSETFILETYPE[4]:
        navigator = `/assets/editLeasedEquipmentSetup/${asset.id}`;
        break;
      default:
        navigator = `/assets/editFixedAssetSetup/${asset.id}`;
        break;
    }

    const primaryActions = [
      ['Mapping Needed', 'Review for Approval'].includes(status) && {
        'Edit Asset': () => {
          navigate(navigator, {state: `${location.pathname}`});
        },
        'Delete Asset': (helpers: {
          changeShowState: (show: boolean) => void;
        }) => {
          helpers.changeShowState(false);
          confirmDelete(id);
          //deleteAsset(id);
        },
      },
    ];

    const secondaryActions = [
      status === 'Review for Approval' && {
        'Approve Asset': (helpers: {
          changeShowState: (show: boolean) => void;
        }) => {
          helpers.changeShowState(false);

          if (params.returnId) {
            updateApprovingAssetMappings([id], true);
            approveAssetMappings(params.returnId, String(id))
              .then(
                () => {
                  toastRef.current?.showSuccessToast(
                    'Approve asset mapping successfully',
                  );
                  fetchData();
                  updateApprovingAssetMappings([id], false);
                },
                (err) => {
                  const message = err.data.errors?.join(', ');
                  toastRef.current?.showErrorToast(
                    message || 'Approve asset mapping failed',
                  );
                  updateApprovingAssetMappings([id], false);
                },
              )
              .catch((e) => {
                toastRef.current?.showErrorToast(getErrorMessage(e));
                updateApprovingAssetMappings([id], false);
              });
          }
        },
      },
      status === 'Approved' && {
        'Unapprove Asset': (helpers: {
          changeShowState: (show: boolean) => void;
        }) => {
          helpers.changeShowState(false);

          if (params.returnId) {
            setUnapprovingAssetMappings({
              ...unapproveAssetMappings,
              [id]: true,
            });
            unapproveAssetMappings(params.returnId, String(id))
              .then(
                () => {
                  toastRef.current?.showSuccessToast(
                    'Unapprove asset mapping successfully',
                  );
                  fetchData();
                  setUnapprovingAssetMappings({
                    ...unapproveAssetMappings,
                    [id]: false,
                  });
                },
                (err) => {
                  const message = err.data.errors?.join(', ');
                  toastRef.current?.showErrorToast(
                    message || 'Unapprove asset mapping failed',
                  );
                  setUnapprovingAssetMappings({
                    ...unapproveAssetMappings,
                    [id]: false,
                  });
                },
              )
              .catch((e) => {
                toastRef.current?.showErrorToast(getErrorMessage(e));
                setUnapprovingAssetMappings({
                  ...unapproveAssetMappings,
                  [id]: false,
                });
              });
          }
        },
      },
    ];

    return [
      _.map(_.assign({}, ..._.compact(primaryActions)), (onClick, label) => {
        return {
          variant: 'default',
          tooltip: {
            section: TOOLTIP_SECTIONS.TableRowAction,
            keyword: label,
          },
          label,
          onClick,
        } as MenuItem;
      }),
      _.map(_.assign({}, ..._.compact(secondaryActions)), (onClick, label) => {
        if (onClick) {
          let loading = false;
          if (label === 'Approve') {
            loading = isApprovingAssetMappings([id]);
          } else if (label === 'Unapprove') {
            loading = unapprovingAssetMappings[id];
          }
          return {
            variant: 'primary',
            label,
            onClick,
            tooltip: {
              section: TOOLTIP_SECTIONS.TableRowAction,
              keyword: label,
            },
            loading,
          } as MenuItem;
        }
        return {} as MenuItem;
      }),
    ].filter((arr) => !_.isEmpty(arr));
  };

  const {state, dispatch} = useContext(ModalContext);
  const ref = useRef<HTMLButtonElement>(null);
  const [deleteId, setDeleteId] = useState<number>();

  const confirmDelete = (id: number) => {
    dispatch({type: 'OPEN', href: 'comfirm'});
    setDeleteId(id);
  };

  const deleteAsset = (e: React.MouseEvent) => {
    if (params.returnId && deleteId) {
      setDeletingAsset(true);
      updateDeletingAssetMapping([deleteId], true);
      deleteAssetMappings(params.returnId, String(deleteId))
        .then(
          () => {
            setDeletingAsset(false);
            dispatch({type: 'CLOSE'});
            toastRef.current?.showSuccessToast(
              'Delete asset mapping successfully',
            );
            fetchData();
            updateDeletingAssetMapping([deleteId], false);
          },
          (err) => {
            const message = err.data.errors?.join(', ');
            toastRef.current?.showErrorToast(
              message || 'Delete asset mapping failed',
            );
            updateDeletingAssetMapping([deleteId], false);
          },
        )
        .catch((e) => {
          toastRef.current?.showErrorToast(getErrorMessage(e));
          updateDeletingAssetMapping([deleteId], false);
          setDeletingAsset(false);
          dispatch({type: 'CLOSE'});
        });
    }
    e.stopPropagation();
  };

  useEffect(() => {
    ref.current && ref.current.blur();
  }, [state.isOpen]);

  const handleReportableChange = (item: ReturnAssetMapping, oldValue: boolean | null, newValue: boolean) => {
    if (newValue !== oldValue && !updatingAssetMappings) {
      clickSaveItem(item?.id, {
        reportable: newValue,
      });
    }
  }

  const handleTaxableChange = (item: ReturnAssetMapping, oldValue: boolean | null, newValue: boolean) => {
    if (newValue !== oldValue && !updatingAssetMappings) {
      clickSaveItem(item?.id, {
        taxable: newValue,
      });
    }
  }

  return (
    <div className={style['main-content']}>
      {/* Braedcrumb */}
      <Breadcrumb items={breadcrumb} />
      <ToastMessage ref={toastRef} className={style['toast']} />
      <h2>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageTitle}
          tooltipKey='Edit Account Mapping & Taxability'
        >
          <span>Edit Account Mapping & Taxability</span>
        </TooltipWrapper>
      </h2>
      <div className={style['main']}>
        <div>
          <div className={style['info-row']}>
            {renderPropertyInfoField(
              'Assessor Name',
              returnInfo?.assessorAccountData?.assessor?.name,
            )}
            {renderPropertyInfoField(
              'Account Number',
              returnInfo?.assessorAccountData?.number,
            )}
            {renderPropertyInfoField(
              'Property Number',
              returnInfo?.assessorAccountData?.property?.number,
            )}
            {renderPropertyInfoField(
              'Property Name',
              returnInfo?.assessorAccountData?.property?.name,
            )}
            {renderPropertyInfoField(
              'Property Address',
              (returnInfo?.assessorAccountData?.property?.address.line1
                ? returnInfo?.assessorAccountData?.property?.address.line1 + ', '
                : '') +
                (returnInfo?.assessorAccountData?.property?.address.line2
                  ? returnInfo?.assessorAccountData?.property?.address.line2 + ', '
                  : '') +
                (returnInfo?.assessorAccountData?.property?.address.state?.abbreviation
                  ? returnInfo?.assessorAccountData?.property?.address.state
                      ?.abbreviation + ', '
                  : '') +
                (returnInfo?.assessorAccountData?.property?.address.postalCode || ''),
            )}
          </div>
          <div className='table-layout'>
            <Table
              id='editAccountMappingTaxability'
              rows={items}
              onQueryChanged={(query) => {
                fetchData(query);
              }}
              paginate
              sortable
              selectable={{
                selected,
                onChange: (s) => {
                  setSelected(s);
                },
              }}
              totalRows={total}
              filterable={false}
              loading={fetchingData}
              dynamicColumns
            >
              {({renderFilterButton}) => [
                <TopBar key='topBar'>
                  <div className={style['top-bar-wrapper']}>
                    <div className={style['top-bar']}>
                      <div className={style['bar-left']}>
                        <FieldSelect
                          classnames={style['filter-group']}
                          labelText='Assigned Return Form'
                          options={returnFormOptions}
                          selectId={returnInfo?.formId}
                          onSelect={(selectedOption) => {
                            if (
                              returnInfo &&
                              selectedOption &&
                              _.toNumber(selectedOption.value) !== returnInfo.formId
                            ) {
                              setUpdatingReturnForm(true);
                              updateReturnForm(returnInfo.id, {
                                formId: _.toNumber(selectedOption.value),
                              })
                                .then((res) => {
                                  const errorMessage = getErrorMessage(res);
                                  if (errorMessage) {
                                    toastRef.current?.showErrorToast(
                                      errorMessage,
                                    );
                                  } else {
                                    toastRef.current?.showSuccessToast(
                                      'Update successfully',
                                    );
                                    setReturnInfo(res.data);
                                    fetchData();
                                  }
                                  setUpdatingReturnForm(false);
                                  //eslint-disable-next-line
                                })
                                //eslint-disable-next-line
                                .catch((e: any) => {
                                  toastRef.current?.showErrorToast(
                                    getErrorMessage(e),
                                  );
                                  setUpdatingReturnForm(false);
                                });
                            }
                          }}
                          force
                          disabled={
                            returnInfo?.status === 'Approved' ||
                            returnInfo?.status === 'Filed'
                          }
                        />
                        <FieldSelect
                          classnames={style['filter-group']}
                          labelText='Assigned Depreciation Schedule Collection'
                          options={depreciationScheduleCollectionOptions}
                          selectId={
                            returnInfo?.depreciationScheduleCollectionId
                          }
                          onSelect={(selectedOption) => {
                            if (
                              returnInfo &&
                              selectedOption &&
                              _.toNumber(selectedOption.value) !==
                                returnInfo.depreciationScheduleCollectionId
                            ) {
                              setUpdatingReturnDepreciationScheduleCollection(
                                true,
                              );
                              updateReturnDepreciationScheduleCollection(
                                returnInfo.id,
                                {
                                  depreciationScheduleCollectionId:
                                  _.toNumber(selectedOption.value) as number,
                                },
                              )
                                .then((res) => {
                                  const errorMessage = getErrorMessage(res);
                                  if (errorMessage) {
                                    toastRef.current?.showErrorToast(
                                      errorMessage,
                                    );
                                  } else {
                                    toastRef.current?.showSuccessToast(
                                      'Update successfully',
                                    );
                                    setUpdatingReturnDepreciationScheduleCollection(
                                      false,
                                    );
                                    setReturnInfo(res.data);
                                    fetchData();
                                  }
                                  setUpdatingReturnDepreciationScheduleCollection(
                                    false,
                                  );
                                })
                                .catch((e) => {
                                  toastRef.current?.showErrorToast(
                                    getErrorMessage(e),
                                  );
                                  setUpdatingReturnDepreciationScheduleCollection(
                                    false,
                                  );
                                });
                            }
                          }}
                          hideNullOption={true}
                          disabled={
                            returnInfo?.status === 'Approved' ||
                            returnInfo?.status === 'Filed'
                          }
                        />
                        <FieldSelect
                          classnames={style['filter-group']}
                          labelText='Assigned Index Table Collection'
                          options={indexTableCollectionOptions}
                          selectId={returnInfo?.indexTableCollectionId}
                          disabled={
                            selectedCompositeCollection ||
                            returnInfo?.status === 'Approved' ||
                            returnInfo?.status === 'Filed'
                          }
                          disabledMessage={
                            selectedCompositeCollection ||
                            returnInfo?.status === 'Approved' ||
                            returnInfo?.status === 'Filed' ?
                            'Index Table Collections are not applicable for composite Depreciation Schedule Collections' :
                            ''
                          }
                          onSelect={(selectedOption) => {
                            if (
                              returnInfo &&
                              selectedOption &&
                              _.toNumber(selectedOption.value) !==
                                returnInfo?.indexTableCollectionId
                            ) {
                              setUpdatingReturnIndexTable(true);
                              updateReturnIndexTable(returnInfo.id, {
                                indexTableCollectionId:
                                _.toNumber(selectedOption.value) as number,
                              })
                                .then((res) => {
                                  const errorMessage = getErrorMessage(res);
                                  if (errorMessage) {
                                    toastRef.current?.showErrorToast(
                                      errorMessage,
                                    );
                                  } else {
                                    toastRef.current?.showSuccessToast(
                                      'Update successfully',
                                    );
                                    setReturnInfo(res.data);
                                    fetchData();
                                  }
                                  setUpdatingReturnIndexTable(false);
                                })
                                .catch((e) => {
                                  toastRef.current?.showErrorToast(
                                    getErrorMessage(e),
                                  );
                                  setUpdatingReturnIndexTable(false);
                                });
                            }
                          }}
                          hideNullOption={true}
                        />
                      </div>
                      <div className={style['bar-right']}>
                        {renderFilterButton()}
                      </div>
                    </div>
                    {selected.length > 0 && (
                      <>
                        <div className={style['selected-items-action']}>
                          <div className={style['batch-action-fields']}>
                            <div className={style['selected-items']}>
                              <TooltipWrapper
                                tooltipSection={TOOLTIP_SECTIONS.Common}
                                tooltipKey='Selected Assets'
                              >
                                <span>Selected Assets ({selected.length})</span>
                              </TooltipWrapper>
                            </div>
                            <FieldSelectTable
                              classnames={style['group-field-select']}
                              labelText='Form Group'
                              selectId={batchGroupId}
                              options={formGroupOptions}
                              onSelect={(selectedOption) => {
                                setBatchGroupId(
                                  (selectedOption?.value as number) ?? null,
                                );
                              }}
                              disabled={!batchEditable}
                            />
                            <FieldSelectTable
                              classnames={style['group-field-select']}
                              labelText='Depreciation Schedule'
                              selectId={batchDepreciationScheduleId}
                              options={depreciationScheduleOptions}
                              onSelect={(selectedOption) => {
                                setBatchDepreciationScheduleId(
                                  (selectedOption?.value as number) ?? null,
                                );
                              }}
                              disabled={!batchEditable}
                            />
                            <FieldSelectTable
                              classnames={style['group-field-select']}
                              labelText='Index Table'
                              selectId={batchIndexTableId}
                              options={indexTableOptions}
                              onSelect={(selectedOption) => {
                                setBatchIndexTableId(
                                  (selectedOption?.value as number) ?? null,
                                );
                              }}
                              disabled={
                                selectedCompositeCollection || !batchEditable
                              }
                              disabledMessage={
                                selectedCompositeCollection || !batchEditable ?
                                'Index Tables are not applicable for composite Depreciation Schedules' :
                                ''
                              }
                            />
                            <FieldToggleCheckbox
                              classnames={style['group-field-toggle']}
                              label='Taxable'
                              value={batchTaxable}
                              onChange={(newVal) => {
                                setBatchTaxable(newVal);
                              }}
                              disabled={!batchEditable}
                            />
                            <FieldToggleCheckbox
                              classnames={style['group-field-toggle']}
                              label='Reportable'
                              value={batchReportable}
                              onChange={(newVal) => {
                                setBatchReportable(newVal);
                              }}
                              disabled={!batchEditable}
                            />
                          </div>
                          <div className={style['batch-action-buttons']}>
                            <TooltipWrapper
                              tooltipSection={TOOLTIP_SECTIONS.TableAction}
                              tooltipKey='Approve'
                            >
                              <button
                                type='button'
                                className='primary'
                                disabled={
                                  isApprovingAssetMappings(selectedIds) ||
                                  !canApproveSelected
                                }
                                onClick={() => {
                                  if (params.returnId) {
                                    updateApprovingAssetMappings(
                                      selectedIds,
                                      true,
                                    );
                                    approveAssetMappings(
                                      params.returnId,
                                      selectedIds.join(','),
                                    )
                                      .then(
                                        () => {
                                          toastRef.current?.showSuccessToast(
                                            'Approve asset mapping successfully',
                                          );
                                          fetchData();
                                          updateApprovingAssetMappings(
                                            selectedIds,
                                            false,
                                          );
                                        },
                                        (err) => {
                                          const message =
                                            err.data.errors?.join(', ');
                                          toastRef.current?.showErrorToast(
                                            message ||
                                              'Approve asset mapping failed',
                                          );
                                          updateApprovingAssetMappings(
                                            selectedIds,
                                            false,
                                          );
                                        },
                                      )
                                      .catch((e) => {
                                        toastRef.current?.showErrorToast(
                                          getErrorMessage(e),
                                        );
                                        updateApprovingAssetMappings(
                                          selectedIds,
                                          false,
                                        );
                                      });
                                  }
                                }}
                              >
                                {`Approve (${selected.length})`}
                                {isApprovingAssetMappings(selectedIds) ? (
                                  <LoaderIndicator
                                    className='button-loading'
                                    loading={true}
                                  />
                                ) : null}
                              </button>
                            </TooltipWrapper>
                            <TooltipWrapper
                              tooltipSection={TOOLTIP_SECTIONS.TableAction}
                              tooltipKey='Save'
                            >
                              <button
                                className='default-button'
                                onClick={clickSaveBatch}
                                disabled={!batchEditable || batchSaveDisabled}
                              >
                                Save
                              </button>
                            </TooltipWrapper>
                            <TooltipWrapper
                              tooltipSection={TOOLTIP_SECTIONS.TableAction}
                              tooltipKey='Cancel'
                            >
                              <button
                                className='default-button'
                                onClick={clickCancelBatch}
                                disabled={!batchEditable}
                              >
                                Cancel
                              </button>
                            </TooltipWrapper>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </TopBar>,
                <Column
                  key='Status'
                  label='Status'
                  prop='status'
                  filterable
                  editor={{
                    type: InputType.Select,
                    options: ASSETS_STATUSES,
                    multi: true,
                  }}
                >
                  {(status: string) => (
                    <span
                      className={classNames('badge-status', kebabCase(status))}
                    >
                      {status}
                    </span>
                  )}
                </Column>,
                <Column
                  id='asset.type'
                  key='Asset Type'
                  label='Asset Type'
                  filterable
                  editor={{
                    type: InputType.Select,
                    options: ASSETFILETYPE,
                    multi: true,
                  }}
                  accessor={(row) => {
                    return row.asset.type;
                  }}
                />,
                <Column
                  id='asset.class'
                  key='Asset Class'
                  label='Asset Class'
                  accessor={(row) => row.asset.class}
                />,
                <Column
                  id='asset.number'
                  key='Asset Number'
                  label='Asset Number'
                  accessor={(row) => row.asset.number}
                />,
                <Column
                  id='asset.description'
                  key='Asset Description'
                  label='Asset Description'
                  accessor={(row) => row.asset.description || ''}
                />,
                <Column
                  id='originalCost'
                  key='Original Cost'
                  label='Original Cost'
                  accessor={(row) => row.originalCost}
                />,
                <Column
                  id='assessedValue'
                  key='Assessed Value'
                  label='Assessed Value'
                  accessor={(row) => row.assessedValue}
                />,
                <Column
                  key='Avalara Standard Asset Class'
                  label='Avalara Standard Asset Class'
                  prop='commonClassId'
                  editable={false}
                  filterable
                  editor={{
                    type: InputType.Select,
                    options: standardAssetClassOptions,
                    multi: true,
                    getLabel: (option) => {
                      return (option as SelectOption).label;
                    },
                    getValue: (option) => {
                      return (option as SelectOption).value;
                    },
                  }}
                >
                  {(value: number, item: ReturnAssetMapping) => (
                    <FieldSelectTable
                      classnames={style['item-select-field']}
                      labelText=''
                      options={standardAssetClassOptions}
                      selectId={value}
                      onSelect={(selectedOption) => {
                        if (selectedOption?.value !== value) {
                          setFetchingDefaultMappings(true);
                          getDefaultMappings(
                            toNumber(params.returnId),
                            selectedOption?.value as number,
                          )
                            .then((res) => {
                              clickSaveItem(item?.id, {
                                ...res.data,
                                commonClassId: selectedOption?.value as number,
                              });
                              setFetchingDefaultMappings(false);
                            })
                            .catch(() => {
                              setFetchingDefaultMappings(false);
                            });
                        }
                      }}
                      disabled={!colIsEditable(item)}
                      force={false}
                      dropdownWidth={350}
                    />
                  )}
                </Column>,
                <Column
                  key='Taxable'
                  label='Taxable'
                  prop='taxable'
                  filterable
                  editable={false}
                  editor={{
                    type: InputType.Select,
                    options: [
                      {
                        name: 'Taxable',
                        value: true,
                      },
                      {
                        name: 'Non - Taxable',
                        value: false,
                      },
                    ],
                    multi: true,
                    getLabel: (option) => {
                      return (option as {name: string; value: boolean}).name;
                    },
                    getValue: (option) => {
                      return (option as {name: string; value: boolean}).value;
                    },
                  }}
                >
                  {(value: boolean | null, item: ReturnAssetMapping) => (
                    <FieldToggleCheckbox
                      label=''
                      value={value ?? false}
                      onChange={(newVal) => {
                        handleTaxableChange(item, value, newVal);
                      }}
                      disabled={!colIsEditable(item)}
                    />
                  )}
                </Column>,
                <Column
                  key='Reportable'
                  label='Reportable'
                  prop='reportable'
                  editable={false}
                  filterable
                  editor={{
                    type: InputType.Select,
                    options: [
                      {
                        name: 'Reportable',
                        value: true,
                      },
                      {
                        name: 'Non - Reportable',
                        value: false,
                      },
                    ],
                    multi: true,
                    getLabel: (option) => {
                      return (option as {name: string; value: boolean}).name;
                    },
                    getValue: (option) => {
                      return (option as {name: string; value: boolean}).value;
                    },
                  }}
                >
                  {(value: boolean | null, item: ReturnAssetMapping) => (
                    <FieldToggleCheckbox
                      label=''
                      value={value ?? false}
                      onChange={(newVal) => {
                        handleReportableChange(item, value, newVal);
                      }}
                      disabled={!colIsEditable(item)}
                    />
                  )}
                </Column>,
                <Column
                  key='Form Group'
                  label='Form Group'
                  prop='formGroupId'
                  filterable
                  editable={false}
                  editor={{
                    type: InputType.Select,
                    options: formGroupOptions,
                    getLabel: (option) => {
                      return (option as SelectOption).label;
                    },
                    getValue: (option) => {
                      return (option as SelectOption).value;
                    },
                    multi: true,
                  }}
                >
                  {(value: number, item: ReturnAssetMapping) => (
                    <FieldSelectTable
                      classnames={style['item-select-field']}
                      labelText=''
                      options={formGroupOptions}
                      defaultOptionText={item?.reportable ? 'Select' : 'Not Reportable'}
                      selectId={value}
                      onSelect={(selectedOption) => {
                        if (selectedOption?.value !== value) {
                          clickSaveItem(item?.id, {
                            formGroupId:
                              (selectedOption?.value as number) ?? null,
                          });
                        }
                      }}
                      disabled={!colIsEditable(item)}
                      dropdownWidth={350}
                    />
                  )}
                </Column>,
                <Column
                  key='Depreciation'
                  label='Depreciation'
                  prop='depreciationScheduleId'
                  filterable
                  editable={false}
                  editor={{
                    type: InputType.Select,
                    options: depreciationScheduleOptions,
                    getLabel: (option) => {
                      return (option as SelectOption).label;
                    },
                    getValue: (option) => {
                      return (option as SelectOption).value;
                    },
                    multi: true,
                  }}
                >
                  {(value: number, item: ReturnAssetMapping) => (
                    <FieldSelectTable
                      classnames={style['item-select-field']}
                      labelText=''
                      options={depreciationScheduleOptions}
                      selectId={value}
                      onSelect={(selectedOption) => {
                        if (selectedOption?.value !== value) {
                          clickSaveItem(item?.id, {
                            depreciationScheduleId:
                              (selectedOption?.value as number) ?? null,
                          });
                        }
                      }}
                      disabled={!colIsEditable(item)}
                      dropdownWidth={350}
                    />
                  )}
                </Column>,
                <Column
                  key='Index Table'
                  label='Index Table'
                  prop='indexTableId'
                  editable={false}
                  editor={{
                    type: InputType.Select,
                    options: indexTableOptions,
                    getLabel: (option) => {
                      return (option as SelectOption).label;
                    },
                    getValue: (option) => {
                      return (option as SelectOption).value;
                    },
                  }}
                >
                  {(value: number, item: ReturnAssetMapping) => (
                    <FieldSelectTable
                      classnames={style['item-select-field']}
                      labelText=''
                      options={indexTableOptions}
                      selectId={value}
                      onSelect={(selectedOption) => {
                        if (selectedOption?.value !== value) {
                          clickSaveItem(item?.id, {
                            indexTableId:
                              (selectedOption?.value as number) ?? null,
                          });
                        }
                      }}
                      disabled={selectedCompositeCollection || !colIsEditable(item)}
                      disabledMessage={
                        selectedCompositeCollection || !colIsEditable(item) ?
                        'Index Tables are not applicable for composite Depreciation Schedules' :
                        ''
                      }
                      dropdownWidth={350}
                    />
                  )}
                </Column>,
                <Column
                  id='actions'
                  key='actions'
                  label='Actions'
                  editable={false}
                >
                  {(item: ReturnAssetMapping) =>
                    item.status !== 'Filed' && (
                      <div className={style['actions-column-cell']}>
                        <TableMenu
                          toggle={
                            <button type='button'>
                              <i className={style['icon-3-dots']} />
                            </button>
                          }
                          menuItems={getMenuItems(item)}
                        />
                      </div>
                    )
                  }
                </Column>,
                <Column
                  key='Addition'
                  label='Addition'
                  prop='asset.isAddition'
                  filterable
                  hidden
                  editable={false}
                  editor={{
                    type: InputType.Select,
                    options: [
                      {
                        name: 'Addition',
                        value: true,
                      },
                      {
                        name: 'Non - Addition',
                        value: false,
                      },
                    ],
                    multi: true,
                    getLabel: (option) => {
                      return (option as {name: string; value: boolean}).name;
                    },
                    getValue: (option) => {
                      return (option as {name: string; value: boolean}).value;
                    },
                  }}
                >
                  {(value: boolean | null, item: ReturnAssetMapping) => (
                    <FieldToggleCheckbox
                      label=''
                      value={value ?? false}
                      onChange={(newVal) => {
                        if (newVal !== value) {
                          clickSaveItem(item?.id, {
                            isAddition: newVal,
                          });
                        }
                      }}
                      disabled={true}
                    />
                  )}
                </Column>,
                <Column
                  key='Disposed'
                  label='Disposed'
                  prop='asset.disposed'
                  filterable
                  hidden
                  editable={false}
                  editor={{
                    type: InputType.Select,
                    options: [
                      {
                        name: 'Disposed',
                        value: true,
                      },
                      {
                        name: 'Non - Disposed',
                        value: false,
                      },
                    ],
                    multi: true,
                    getLabel: (option) => {
                      return (option as {name: string; value: boolean}).name;
                    },
                    getValue: (option) => {
                      return (option as {name: string; value: boolean}).value;
                    },
                  }}
                >
                  {(value: boolean | null, item: ReturnAssetMapping) => (
                    <FieldToggleCheckbox
                      label=''
                      value={value ?? false}
                      onChange={(newVal) => {
                        if (newVal !== value) {
                          clickSaveItem(item?.id, {
                            disposed: newVal,
                          });
                        }
                      }}
                      disabled={true}
                    />
                  )}
                </Column>,
                <Column
                  id='asset.acquisitionDate'
                  key='Acquisition Date'
                  label='Acquisition Date'
                  filterable
                  hidden
                  editor={{
                    label: ['Acquisition From Date', 'Acquisition To Date'],
                    type: InputType.DateRange,
                  }}
                  accessor={(row) => {
                    return row.asset.acquisitionDate ? formatDate(row.asset.acquisitionDate) : '';
                  }}
                />,
              ]}
            </Table>
          </div>
        </div>
        <hr />
        <div className={style['buttons']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Back'
          >
            <button type='button' className='primary' onClick={clickBack}>
              Back
            </button>
          </TooltipWrapper>
        </div>
      </div>

      <Modal
        title='Confirmation'
        body={<p>Are you sure you want to delete?</p>}
        isOpen={state.isOpen && state.href === 'comfirm'}
        onClose={() => {
          setDeletingAsset(false);
        }}
        footer={
          <div className='buttons'>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Confirm'
            >
              <button
                className='primary'
                onClick={(e) => deleteAsset(e)}
                disabled={deletingAsset}
                ref={ref}
              >
                Confirm
                {deletingAsset ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Cancel'
            >
              <button
                className='secondary'
                onClick={() => {
                  setDeletingAsset(false);
                  dispatch({type: 'CLOSE'});
                }}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        }
      />

      <Loader
        isOpen={
          fetchingReturnDetail ||
          fetchingReturnForms ||
          fetchingDepreciationScheduleCollections ||
          fetchingIndexTableCollections ||
          updatingReturnForm ||
          updatingReturnDepreciationScheduleCollection ||
          updatingReturnIndexTable ||
          fetchingStandardAssetClasses ||
          fetchingFormGroups ||
          fetchingDepreciationSchedules ||
          fetchingIndexTables ||
          updatingAssetMappings ||
          fetchingDefaultMappings
        }
      />
    </div>
  );
}

export default EditAccountMappingTaxability;
