import React, {useState, useEffect, useContext, useRef} from 'react';
import _ from 'lodash';
import style from './Assets.module.scss';
import {
  IToastMessage,
  OriginColumns,
  TypeColumnsKeys,
} from '../../../interface';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage, { ToastMessageRefType } from '../../../components/ToastMessage/ToastMessage';
import Tabs from '../../../components/Tabs/Tabs';
import {
  ASSETFILETYPE,
  FIXEDASSETFILECOLUMNS,
  INVENTORYFILECOLUMNS,
  LEASEEQUIPMENTFILECOLUMNS,
  LEASEVEHICLEFILECOLUMNS,
  VEHICLEFILECOLUMNS,
  ASSETFILETYPEINFORMATION,
  ROLLOVERINVENTORYASSETFILECOLUMNS,
  ROLLOVERASSETFILECOLUMNS,
} from '../../../constants';
import AssetsTable from '../../../components/AssetsTable/AssetsTable';
import {
  FIXEDASSETFILETOFIELD,
  INVENTORYFILETOFIELD,
  LEASEDEQUIPMENTFILETOFIELD,
  LEASEDVEHICLEFILETOFIELD,
  TOOLTIP_SECTIONS,
  VEHICLEFILETOFIELD,
} from '../../../enums';
import reMatchKeys from '../../../utils/reMatchKeys';
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import actions from '../../../redux/actions';
import ModalContext from '../../../context/ModalContext';
import Modal from '../../../components/Modal/Modal';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import useDashboardInfo from '../../../hook/useDashboardInfo';

const Breadcrumbs = {
  rollover: [
    {
      value: "Assets",
      href: "/assets",
    },
    {
      value: "Rollover Assets",
    },
  ],
  mapping: [
    {
      value: "Assets",
      href: "/assets",
    },
    { value: "Add Asset", href: "/assets/assetsUpload" },
    { value: "File Mapping", href: "/assets/assetsMapFile" },
    { value: "File Review", href: "/assets/assetsReview" },
    { value: "Manage Assets" },
  ],
  manage: [
    {
      value: "Assets",
      href: "#",
    },
    { value: "Manage Assets" },
  ],
};

function Assets() {
  // Breadcrumb, dashboard depend on user skip or not, property link depend where is the page from
  const matchRollover = useMatch('/assets/rollover');
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { taxReturnProcess } = useDashboardInfo();
  const breadcrumb =
    (matchRollover && Breadcrumbs.rollover) ||
    (_.get(location.state, "length") && Breadcrumbs.mapping) ||
    Breadcrumbs.manage;

  // Properties list
  const {state, dispatch} = useContext(ModalContext);
  const [type, setType] = useState<string>(ASSETFILETYPE[0]);
  const [typeModal, setTypeModal] = useState<string>(ASSETFILETYPE[0]);

  //Toast message
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [hasProperties, setHasProperties] = useState<boolean>(false);
  useEffect(() => {
    if (sessionStorage.getItem('importAssetsLength')) {
      setToastMessage({
        visible: true,
        message: `${sessionStorage.getItem(
          'importAssetsLength',
        )} assets were successfully imported.`,
        type: 'success',
      });
      sessionStorage.removeItem('importAssetsLength');
    }
  }, []);

  useEffect(() => {
    if (taxReturnProcess) {
      if (taxReturnProcess.properties) {
        setHasProperties(taxReturnProcess.properties);
      } else {
        setToastMessage({
          visible: true,
          message: `You must add properties first`,
          type: 'error',
        });
      }
    }
  }, [taxReturnProcess]);

  const [columns, setColumns] = useState<OriginColumns[]>(
    FIXEDASSETFILECOLUMNS,
  );
  const [columnsKeys, setColumnsKeys] = useState<TypeColumnsKeys>(
    FIXEDASSETFILETOFIELD,
  );
  const toastRef = useRef<ToastMessageRefType | null>(null);

  useEffect(() => {
    switch (type) {
      case ASSETFILETYPE[1]:
        setColumns(matchRollover ? ROLLOVERINVENTORYASSETFILECOLUMNS : INVENTORYFILECOLUMNS);
        setColumnsKeys(reMatchKeys(INVENTORYFILETOFIELD));
        break;
      case ASSETFILETYPE[2]:
        setColumns(matchRollover ? ROLLOVERASSETFILECOLUMNS : VEHICLEFILECOLUMNS);
        setColumnsKeys(reMatchKeys(VEHICLEFILETOFIELD));
        break;
      case ASSETFILETYPE[3]:
        setColumns(matchRollover ? ROLLOVERASSETFILECOLUMNS : LEASEVEHICLEFILECOLUMNS);
        setColumnsKeys(reMatchKeys(LEASEDVEHICLEFILETOFIELD));
        break;
      case ASSETFILETYPE[4]:
        setColumns(matchRollover ? ROLLOVERASSETFILECOLUMNS : LEASEEQUIPMENTFILECOLUMNS);
        setColumnsKeys(reMatchKeys(LEASEDEQUIPMENTFILETOFIELD));
        break;
      default:
        setColumns(matchRollover ? ROLLOVERASSETFILECOLUMNS : FIXEDASSETFILECOLUMNS);
        setColumnsKeys(reMatchKeys(FIXEDASSETFILETOFIELD));
        break;
    }
    setTypeModal(type);
  }, [type]);

  const [throwError, setThrowError] = useState<string>('');
  useEffect(() => {
    if (throwError) {
      setToastMessage({
        visible: true,
        message: throwError,
        type: 'error',
      });
      setThrowError('');
    }
  }, [throwError]);

  const openModalHandle = () => {
    dispatch({type: 'OPEN', href: 'selectAssetType'});
  };

  const setTab = (v: string) => {
    setSearchParams({assetTab: v});
  };

  useEffect(() => {
    if (searchParams.get('assetTab')) {
      setType(searchParams.get('assetTab') as string);
    } else {
      setType(ASSETFILETYPE[0]);
    }
  }, [searchParams]);

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        <ToastMessage
          ref={toastRef}
          status={toastMessage}
        />
        <div className={style['main']}>
          <h2>
            {matchRollover ? (
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                tooltipKey='Rollover Assets'
              >
                <span>Rollover Assets</span>
              </TooltipWrapper>
            ) : (
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                tooltipKey='Manage Assets'
              >
                <span>Manage Assets</span>
              </TooltipWrapper>
            )}
          </h2>
          {/* Added Properties */}
          <div className={style['section']}>
            <Tabs
              tabs={ASSETFILETYPE}
              selected={type}
              onSelect={(v) => {
                setTab(v);
              }}
            >
              <div className={style.tableContainer}>
                <AssetsTable
                  toastRef={toastRef}
                  standardColumns={_.map(columns, 'propertyData')}
                  additionColumn={matchRollover ? ['Rollover Status'] : ['Asset Status']}
                  checkedColumns={_.concat(
                    _.map(
                      _.filter(columns, ['mappingPriority', 'Required']),
                      'propertyData',
                    ),
                    ['Asset Status'],
                    ['Rollover Status']
                  )}
                  mappedKeys={columnsKeys}
                  type={type}
                  disabledAdd={!hasProperties}
                  onError={setThrowError}
                  openModal={openModalHandle}
                  matchRollover={!!matchRollover}
                />
              </div>
            </Tabs>
            <div className={style['buttons']}>
              {matchRollover ? (
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.PageAction}
                  tooltipKey='Cancel'
                >
                  <Link to='/assets/assetsUpload' className='button secondary'>
                    Cancel
                  </Link>
                </TooltipWrapper>
              ) : (
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.PageAction}
                  tooltipKey='Return to dashboard'
                >
                  <Link to='/dashboard' className='button primary'>
                    Return to dashboard
                  </Link>
                </TooltipWrapper>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className='selectAssetType'
        title='Add Assets'
        body={
          <>
            <h4>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.Modal}
                tooltipKey='Select type of asset'
              >
                <span>Select type of asset</span>
              </TooltipWrapper>
            </h4>
            <ul className={style['list']}>
              {ASSETFILETYPE &&
                ASSETFILETYPE.map((item, index) => (
                  <li key={index}>
                    <CustomRadio
                      label={item}
                      name='type'
                      value={item}
                      checked={_.isEqual(typeModal, item)}
                      onChange={() => {
                        setTypeModal(item);
                      }}
                    />
                  </li>
                ))}
            </ul>
            {_.get(ASSETFILETYPEINFORMATION, typeModal) && (
              <div className={style['description']}>
                <h5>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Modal}
                    tooltipKey='Select Type'
                  >
                    <span>{typeModal}</span>
                  </TooltipWrapper>
                </h5>
                <p className={style['info']}>
                  {_.get(ASSETFILETYPEINFORMATION, typeModal)}
                </p>
              </div>
            )}
          </>
        }
        isOpen={state.isOpen && state.href === 'selectAssetType'}
        footer={
          <div className='buttons'>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Save and Continue'
            >
              <button
                className='primary'
                onClick={() => {
                  sessionStorage.setItem('assetType', typeModal);
                  dispatch({type: 'CLOSE'});
                  setTimeout(() => {
                    navigate('/assets/assetsUpload');
                  });
                }}
              >
                Save and Continue
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Cancel'
            >
              <button
                className='secondary'
                onClick={() => {
                  dispatch({type: 'CLOSE'});
                }}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        }
      />
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUploadAssetsFile: (value: File | undefined) =>
    dispatch(actions.setUploadAssetsFile(value)),
});

export default connect(null, mapDispatchToProps)(Assets);
