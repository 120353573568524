import { FormikProps } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { AssetField, State, SelectOption } from "../interface";
import removeAssetsEmptyFields from "./removeAssetsEmptyFields";

function useCommonEffects(
    asset: AssetField | undefined,
    states: State[],
    companies: SelectOption[],
    properties: SelectOption[],
    legalEntities: SelectOption[],
    requiredField: string[],
    formik: FormikProps<any>,
    setstatincorporation: React.Dispatch<React.SetStateAction<SelectOption[]>>,
    setPropertyStateId: React.Dispatch<React.SetStateAction<undefined>>,
    setNavBlock: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    newInit: object,
    currentSelected: string
) {
    // Reset all inputs to blank
    useEffect(() => {
        if (asset) {
            const requiredObj = {};
            _.each(requiredField, (field) => {
                _.set(requiredObj, field, null);
            });
            formik.setValues(_.defaults(asset, requiredObj));
        }
    }, [asset]);
    
    // Populate state dropdown
    useEffect(() => {
        setstatincorporation(
            states.map((o: State) => ({
                value: o['id'],
                label: o['abbreviation'],
                name: o['name'],
            })),
        );
    }, [states]);
    
    useEffect(() => {
        if (asset) {
            const requiredObj = {};
            _.each(requiredField, (field) => {
                _.set(requiredObj, field, null);
            });
            const result = _.defaults(asset, requiredObj);
            setNavBlock(
                !_.isEqual(
                    removeAssetsEmptyFields(_.omit(result, 'stateId')),
                    removeAssetsEmptyFields(_.omit(formik.values, 'showOptional', 'stateId')),
                ),
            );
        } else {
            setNavBlock(!_.isEqual(newInit, formik.values));
        }
    }, [asset, formik.values]);
    
    //Auto populate state id for property
    useEffect(() => {
        if (formik.values.propertyId) {
            setPropertyStateId(
                _.get(
                    _.find(properties, ['value', formik.values.propertyId]),
                    'item.address.stateId',
                ),
            );
            formik.setFieldValue('accountId', null);
        } else {
            setPropertyStateId(undefined);
            formik.setFieldValue('accountId', null);
        }
    }, [formik.values.propertyId, properties]);
    
    useEffect(() => {
        if (formik.values.legalEntityId && _.isEqual(currentSelected, 'owner')) {
            const find = _.find(legalEntities, legalEntity =>  legalEntity?.value == formik.values.legalEntityId);
            const _companyId = _.get(find, 'item.companyId');
            let propertyId = null;
            const findProperties = _.filter(properties, property =>  property?.item?.legalEntityId == formik.values.legalEntityId);

            if (_.isEqual(findProperties.length, 1)) {
                propertyId = findProperties[0].value;
            }
            formik.setValues({
                ...formik.values,
                companyId: _.find(companies, ['value', _companyId])
                    ? _companyId
                    : undefined,
                propertyId,
                accountId: null,
            });
        }
    }, [formik.values.legalEntityId, currentSelected, properties]);
      
    useEffect(() => {
        if (_.isEqual(currentSelected, 'company')) {
            if (formik.values.companyId) {
                const findOwners = _.filter(legalEntities, legalEntity =>  legalEntity?.item?.companyId == formik.values.companyId);
                let legalEntityId = null;
                let propertyId = null;
                if (_.isEqual(findOwners.length, 1)) {
                    legalEntityId = findOwners[0].value;
                    const findPropereys = _.filter(properties, property =>  property?.item?.legalEntityId == findOwners[0]?.value);
                    if (_.isEqual(findPropereys.length, 1)) {
                        propertyId = findPropereys[0].value;
                    }
                }
                formik.setValues({
                    ...formik.values,
                    legalEntityId,
                    propertyId,
                    accountId: null,
                });
            } else {
            formik.setValues({
                ...formik.values,
                legalEntityId: null,
                propertyId: null,
                accountId: null,
            });
            }
        }
    }, [formik.values.companyId, currentSelected, legalEntities, properties]);
    
    useEffect(() => {
        if (_.isEqual(companies.length, 1)) {
            formik.setFieldValue('companyId', companies[0].value);
        }
    }, [companies]);
}

export default useCommonEffects;