import React, {useMemo, useEffect, useRef, useCallback} from 'react';
import classNames from 'classnames';
import 'react-day-picker/dist/style.css';
import style from './FieldDate.module.scss';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import moment from 'moment';
import FieldReadonly from '../FieldReadOnly/FieldReadOnly';
import { SDatepicker } from '@avalara/skylab-sdk/react';

export type FieldDatePopupPosition = 'bottom';

interface Props {
  labelText: string;
  primaryFormat?: string;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  onSelect?: (value: string) => void;
  selectValue?: string;
  minDate?: string;
  maxDate?: string;
  recommended?: boolean;
  disabledDates?: string[];
  showCalender?: boolean;
  hideTextField?: boolean;
  hideActionBottom?: boolean;
  hideTodayButton?: boolean;
  hideWeekdaysLabel?: boolean;
  hideSelectMonthYear?: boolean;
  notAllowSelectDateInPicker?: boolean;
  highlightDisabledDays?: boolean;
  formatOutput?: boolean;
  isInReadOnlyMode?: boolean;
}

function FieldDate(props: Props) {
  const {
    labelText,
    primaryFormat = 'MM/DD/YYYY',
    classnames,
    required,
    tooltipText,
    disabled,
    error,
    onSelect,
    selectValue,
    minDate,
    maxDate,
    recommended,
    disabledDates,
    showCalender = false,
    hideTextField = false,
    hideActionBottom = false,
    hideTodayButton = false,
    hideWeekdaysLabel = false,
    hideSelectMonthYear = false,
    notAllowSelectDateInPicker = false,
    highlightDisabledDays = false,
    formatOutput = false,
    isInReadOnlyMode = false,
  } = props;

  const selectDateVal = useMemo(() => {
    if (selectValue && primaryFormat) {
      const momentDate = moment(selectValue, primaryFormat);
      if (momentDate.isValid()) {
        return moment(selectValue, primaryFormat).format('YYYY-MM-DD');
      } else {
        return selectValue;
      }
    }
    return '';
  }, [selectValue, primaryFormat]);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.querySelector('input')?.setAttribute('readonly', 'true');
    ref.current?.querySelector('input')?.addEventListener('click', () => {
      ref.current?.querySelector('button')?.click();
    })
  }, [ref]);

  const onDateChange = useCallback(
    (e: CustomEvent) =>
      onSelect?.(
        formatOutput
          ? moment(e.detail.value || null).format(primaryFormat)
          : e.detail.value || null
      ),
    [onSelect, formatOutput, primaryFormat]
  );

  if (isInReadOnlyMode) {
    return (
      <FieldReadonly
        className={classnames}
        labelText={labelText}
        required={required}
        tooltipText={tooltipText}
        value={selectValue || ''}
      />
    )
  }
  
  return (
    <div
      ref={ref}
      className={classNames('FieldDate', style.container, classnames, {
        error: error,
        [style.showCalender]: showCalender,
        [style.hideTextField]: hideTextField,
        [style.hideActionBottom]: hideActionBottom,
        [style.hideTodayButton]: hideTodayButton,
        [style.hideWeekdaysLabel]: hideWeekdaysLabel,
        [style.hideSelectMonthYear]: hideSelectMonthYear,
        [style.notAllowSelectDateInPicker]: notAllowSelectDateInPicker,
        [style.highlightDisabledDays]: highlightDisabledDays,
      })}
    >
      {/* Lable of input,accept required and tooltip parameters */}
      {!!labelText || recommended || !!tooltipText ? (
        <div
          className={classNames(style['label'], {
            [style['label-recommended']]: recommended,
          })}
        >
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Common}
            tooltipKey={tooltipText || labelText}
          >
            <span>
              {labelText}
              {required && <i className={style['required']}>*</i>}
            </span>
          </TooltipWrapper>
          {recommended && <small>Recommended</small>}
        </div>
      ) : null}

      <div className={style['datepicker-wrapper']}>
        <SDatepicker
          value={selectDateVal}
          minDate={minDate}
          maxDate={maxDate}
          primaryFormat={primaryFormat}
          disabled={!!disabled}
          inputId='date-input'
          inputName='date'
          disabledDates={disabledDates ? JSON.stringify(disabledDates) : '[]'}
          onS-select={onDateChange}
          onS-deselect={onDateChange}
        />

        {/* X Button to clear the selected date */}
        {(selectValue && !notAllowSelectDateInPicker) && (
          <span
            className={classNames(style['clear-datepicker'])}
            onClick={(e) => {
              onDateChange(e as unknown as CustomEvent);
            }}
          >
          </span>
        )}
      </div>
      

      {/* Error message if do not enter any text and if field is required */}
      {error && (
        <span
          className={classNames(style['error-message'], {
            [style['long-message']]: !/Empty/.test(error),
          })}
        >
          {error}
        </span>
      )}
    </div>
  );
}

export default FieldDate;
