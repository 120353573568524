import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import style from './AssessorNotTaxYearForm.module.scss';
import FieldInput from '../FieldInput/FieldInput';
import { useAppSelector } from '../../redux/store';
import { State } from '../../interface';
import FieldSelect from '../FieldSelect/FieldSelect';
import Divider from '../Divider/Divider';

import IconEdit from '../../images/icon-edit.svg';
import IconSave from '../../images/icon-save-grayscaleDark-18x18.svg';
import FieldReadonly from '../FieldReadOnly/FieldReadOnly';
import { FormikProps } from 'formik';
import { IAssessorNotTaxYearFormData } from '../../interface/IAssessorNotTaxYearFormData';
import _ from 'lodash';

interface Props {
  className?: string;
  isInEditMode?: boolean;
  formik?: FormikProps<IAssessorNotTaxYearFormData>;
  isSubmitted?: boolean;
  setIsUpdatedState?: () => void;
}

function AssessorNotTaxYearForm(
  {
    className,
    isInEditMode,
    formik,
    isSubmitted,
    setIsUpdatedState
  }: Props,
) {
  const [isEditing, setIsEditing] = useState(isInEditMode)
  const [isNotTaxSubmitted, setIsNotTaxSubmitted] = useState<boolean>(false);
  const stateInCorporations = useAppSelector(state => (state.states ?? []).map((o: State) => ({
    value: o.id,
    label: o.abbreviation,
    name: o.name,
  })));

  const shouldShowError = useMemo(() => isNotTaxSubmitted || isSubmitted, [isNotTaxSubmitted, isSubmitted]);
  const getErrorMessage = (key: keyof IAssessorNotTaxYearFormData) => {
    return shouldShowError ? formik?.errors?.[key] : undefined;
  }

  return (
    <div className={classNames(className, style['main-content'], 'AssessorNotTaxYearForm')}>

      <div className='d-flex align-items-center justify-content-between'>
        <span className={style.textTitle}>SECTION 1: Not Tax Year Specific</span>
        {!isInEditMode ? (
          <button
            onClick={() => {
              if (isEditing) {
                setIsNotTaxSubmitted(true);
                if (formik?.isValid) {
                  setIsEditing(false);
                  formik?.submitForm();
                }
              } else {
                setIsEditing(true);
              }
            }}
            className={style.btnEdit}
          >
            <img src={isEditing ? IconSave : IconEdit} alt="" />
          </button>
        ) : null}
      </div>

      <Divider className={style.divider1} strokeWidth={2} />

      <div className='d-grid grid-4-columns gap-column-25 gap-row-20'>
        <FieldInput
          labelText='Assessor Name'
          name='name'
          value={formik?.values.name ?? ''}
          onChange={(value) => {
            formik?.setFieldValue('name', value);
          }}
          isInReadOnlyMode={!isEditing}
          error={getErrorMessage('name')}
        />
        <FieldInput
          labelText='ID Number'
          name='id'
          value={formik?.values.id ?? ''}
          onChange={(value) => {
            formik?.setFieldValue('id', value);
          }}
          disabled
          isInReadOnlyMode={!isEditing}
        />
        <FieldSelect
          labelText='State'
          options={stateInCorporations}
          selectId={formik?.values.state}
          onSelect={(value) => {
            formik?.setFieldValue(
              'state',
              _.toNumber(value?.value),
            )
            setIsUpdatedState?.()
          }}
          isInReadOnlyMode={!isEditing}
          hideNullOption
          error={getErrorMessage('state')}
        />
        <FieldInput
          labelText='Assessor Title'
          name='title'
          value={formik?.values.title ?? ''}
          onChange={(value) => {
            formik?.setFieldValue('title', value);
          }}
          isInReadOnlyMode={!isEditing}
          error={getErrorMessage('title')}
        />
        <FieldInput
          labelText='Mailing Address - Line 1'
          name='mailingAddressLine1'
          value={formik?.values.mailingAddressLine1 ?? ''}
          onChange={(value) => {
            formik?.setFieldValue('mailingAddressLine1', value);
          }}
          isInReadOnlyMode={!isEditing}
          error={getErrorMessage('mailingAddressLine1')}
        />
        <FieldInput
          labelText='Mailing Address - Line 2'
          name='mailingAddressLine2'
          value={formik?.values.mailingAddressLine2 ?? ''}
          onChange={(value) => {
            formik?.setFieldValue('mailingAddressLine2', value);
          }}
          isInReadOnlyMode={!isEditing}
        />
        <FieldInput
          labelText='Mailing Address - City'
          name='mailingAddressCity'
          value={formik?.values.mailingAddressCity ?? ''}
          onChange={(value) => {
            formik?.setFieldValue('mailingAddressCity', value);
          }}
          isInReadOnlyMode={!isEditing}
          error={getErrorMessage('mailingAddressCity')}
        />
        <FieldSelect
          labelText='Mailing Address - State'
          options={stateInCorporations}
          selectId={formik?.values.mailingAddressState}
          onSelect={(value) => {
            formik?.setFieldValue(
              'mailingAddressState',
              value?.value,
            )
          }}
          isInReadOnlyMode={!isEditing}
          hideNullOption
          error={getErrorMessage('mailingAddressState')}
        />
        <FieldInput
          labelText='Mailing Address - ZIP'
          name='mailingAddressZip'
          value={formik?.values.mailingAddressZip ?? ''}
          onChange={(value) => {
            formik?.setFieldValue('mailingAddressZip', value);
          }}
          isInReadOnlyMode={!isEditing}
          error={getErrorMessage('mailingAddressZip')}
        />
        {isEditing ? (<FieldInput
          labelText='Website Link'
          name='websiteLink'
          value={formik?.values.websiteLink ?? ''}
          onChange={(value) => {
            formik?.setFieldValue('websiteLink', value);
          }}
          isInReadOnlyMode={!isEditing}
        />) : (
          <FieldReadonly
            labelText='Website Link'
            value={formik?.values.websiteLink ?? ''}
            isLink
          />
        )}
      </div>
    </div>
  );
}

export default AssessorNotTaxYearForm;