import _ from "lodash";
import React, { useEffect } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { SLoader } from "@avalara/skylab-sdk/react";

interface Props {
  isOpen: boolean;
  className?: string;
}

const compare = (prev: Props, next: Props) => {
  return _.isEqual(prev, next) ? true : false;
};

const Loader = React.memo(function Loader(props: Props) {
  const { isOpen, className } = props;
  const div = document.createElement("div");
  const root = document.getElementById("root") as HTMLElement;
  div.setAttribute("class", "modal");
  useEffect(() => {
    if(isOpen){
      setTimeout(() => {               
        if(isOpen){
          root.appendChild(div);
        }
      }, 1000);
    }
    return () => {      
      if (root.contains(div)) {        
        root.removeChild(div);        
      }
    };
  }, [isOpen, div, root]);
  
  return isOpen
    ? ReactDOM.createPortal(
        <div className={classNames("loader-container", className)}>
          <SLoader loading={true} className="loader" />
        </div>,
        div
      )
    : null;
}, compare);

export default Loader;

export const LoaderIndicator = SLoader;