import React, { useEffect, forwardRef, useImperativeHandle, Ref, useMemo } from 'react';
import classNames from 'classnames';
import { IToastMessage, IToastMessageType } from '../../interface';
import style from './ToastMessage.module.scss';
import newId from '../../utils/newId';
import { SToasts } from '@avalara/skylab-sdk/react';

interface Props {
  status?: IToastMessage;
  className?: string;
  visiableHandler?: (value: boolean) => void;
  callback?: () => void;
}

export interface ToastMessageRefType {
  showSuccessToast: (text: string) => void,
  showErrorToast: (text: string) => void,
}

function ToastMessage(props: Props, ref: Ref<ToastMessageRefType>) {
  const { status, className, visiableHandler, callback } = props;

  const toastId = useMemo(() => {
    return newId();
  }, []);

  const showToast = (text: string, type?: IToastMessageType) => {
    const toastData = {
      text,
      id: toastId,
      status: type,
    };

    document
      // eslint-disable-next-line
      .querySelector<any>(`s-toasts#toastId-${toastId}`)
      ?.createToast(toastData);
  }


  useImperativeHandle(ref, () => ({
    showSuccessToast: (text: string) => {
      showToast(text, 'success');
    },
    showErrorToast: (text: string) => {
      showToast(text, 'error');
    }
  }));

  useEffect(() => {
    if (status && status?.visible && toastId) {
      showToast(status?.message, status.type);
    } else {
      document
        // eslint-disable-next-line
        .querySelector<any>(`s-toasts#toastId-${toastId}`)
        ?.dismissAllToasts();
    }
  }, [status, toastId])

  return (
    <SToasts
      id={`toastId-${toastId}`}
      className={classNames(
        style.container,
        className,
      )}
      onS-dismiss={() => {
        visiableHandler && visiableHandler(false);
        callback && callback();
      }}
    />
  );
}

export default forwardRef<ToastMessageRefType, Props>(ToastMessage);
