import React, { useState, useEffect, useRef, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { SelectOption } from '../../interface';
import style from './styles.module.scss';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingOverlay,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { SInputExtended } from '@avalara/skylab-sdk/react';

interface Props {
  labelClassName?: string;
  labelText: string;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  options: SelectOption[];
  onSelect: (value: SelectOption | undefined) => void;
  selectText?: string;
  force?: boolean;
  selectId?: number | null;
  recommended?: boolean;
  dropdownWidth?: number | null;
  defaultOptionText?: string;
  disabledMessage?: string;
}

function FieldSelectTable(props: Props) {
  const {
    labelClassName,
    labelText,
    classnames,
    required,
    tooltipText,
    disabled,
    error,
    options,
    onSelect,
    selectText,
    force,
    recommended,
    selectId,
    dropdownWidth,
    defaultOptionText,
    disabledMessage
  } = props;
  //The selected option
  const [selected, setSelected] = useState<string>();
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchField, setSearchField] = useState<string>('');
  const ulRef = useRef<HTMLUListElement | null>(null);

  const searchOptions = useMemo(
    () => searchKey ? _.filter(options, (o) => {
      const key = searchKey.toLowerCase().trim();
      const searchValue = (o.label || '').toLowerCase().trim()
      return searchValue.indexOf(key) >= 0;
    }) : options,
    [options, searchKey],
  );

  //If options is empty or do not selected,pass empty string to parent
  useEffect(() => {
    if (selectText) {
      if (_.isEmpty(options)) {
        setSelected('');
      } else {
        setSelected(_.find(options, ['label', selectText])?.label as string);
      }
    } else {
      setSelected('');
    }
  }, [selectText, options]);

  useEffect(() => {
    if (selectId) {
      if (_.isEmpty(options)) {
        setSelected('');
      } else {
        // ignore in coverage report because selectId can not be null
        /* istanbul ignore else */
        if (selectId) {
          setSelected(_.find(options, ['value', selectId])?.label as string);
        } else {
          setSelected('');
        }
      }
    } else if (_.isEqual(selectId, null)) {
      setSelected('');
    }
  }, [options, selectId]);

  const [open, setOpen] = useState(false);



  const getVisuallyFocusedLi = (): HTMLLIElement | null => {
    if (ulRef.current) {
      return ulRef.current.querySelector("li.visually-focused") as HTMLLIElement;
    }
    return null;
  }

  const setScrollTop = () => {
    const visuallyFocusedLi = getVisuallyFocusedLi()
    const containerRef = context.refs.floating.current
    if (containerRef && visuallyFocusedLi) {
      const scrollTop = visuallyFocusedLi.offsetTop;
      containerRef.scrollTop = scrollTop - 3;
    }
  }

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    strategy: 'fixed',
    onOpenChange: (openTmp) => {
      setOpen(openTmp)
      setSearchKey('')
      setTimeout(() => {
        setScrollTop();
      });
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({ padding: 8 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${dropdownWidth ?? rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  const getDropdownLeftPosition = (x: number | null) => {
    // Offset dropdown so the left side lines up with left of its container
    const containerElement = ulRef.current?.closest('.form-control')?.querySelector('.select-container');
    const containerWidth = containerElement?.clientWidth ?? 0;
    const dropdownOffsetLeft = dropdownWidth ? (dropdownWidth - containerWidth) / 2 : 0; 
    return (x ?? 0) + dropdownOffsetLeft;
  };

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
  ]);

  return (
    <label
      className={classNames(
        'form-control',
        style['form-control'],
        classnames,
        { error: error },
        { [style['disabled']]: disabled }
      )}
      title={disabledMessage ?? ''}
    >
      {/* Lable of input,accept required and tooltip parameters */}
      {!!labelText && (
        <div
          className={classNames(
            style['label'],
            { [style['label-recommended']]: recommended },
            labelClassName
          )}
        >
          {labelText}
          {required && <i className={style['required']}>*</i>}
          {recommended && <small>Recommended</small>}
          {tooltipText && (
            <i className={style['tooltip']}>
              <span className={style['tip']}>{tooltipText}</span>
            </i>
          )}
        </div>
      )}

      <div
        {...getReferenceProps({
          ref: reference,
          className: classNames(style['input-control'], 'select-container'),
          tabIndex: 0,
        })}
      >
        {/* Selected option field */}
        <div
          className={classNames(style['selected'], {
            [style['default']]: !selected,
          })}
        >
          <span
            title={disabledMessage ? disabledMessage : selected ? selected : defaultOptionText ?? 'Select'}
          >
            {selected ? selected : defaultOptionText ?? 'Select'}
          </span>
        </div>
      </div>
      {open && (
        <FloatingOverlay lockScroll className={style['floating-overlay']}>
          <FloatingFocusManager context={context} preventTabbing>
            <div
              {...getFloatingProps({
                ref: floating,
                className: style['options'],
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: getDropdownLeftPosition(x),
                  overflow: 'auto',
                },
              })}
            >
              <SInputExtended
                value={searchField}
                className={style['search-container']}
                aria-label="Search"
                // eslint-disable-next-line
                type={'search-live' as any}
                inputId="search-input"
                onS-clear={() => {
                  setSearchKey('')
                  setSearchField('')
                }}
                onS-input={(e: CustomEvent) => {
                  setSearchKey(e.detail.value)
                  setSearchField(e.detail.value)
                }}
              />
              <ul ref={ulRef}>
                {!force && searchOptions.length ? (
                  <li
                    onClick={(evt) => {
                      setSelected('');
                      onSelect(undefined);
                      setOpen(false);
                      evt.stopPropagation();
                    }}
                  >
                    {defaultOptionText ?? 'Select'}
                  </li>
                ) : null}
                {/* Options list */}
                {searchOptions.map((option, index) => {
                  const isSelected = _.isEqual(option.label, selected);
                  return (
                    <li
                      key={index}
                      onClick={(evt) => {
                        setSelected(option.label);
                        onSelect(option);
                        setOpen(false);
                        evt.stopPropagation();
                      }}
                      className={classNames({
                        [style['active']]: isSelected,
                        'visually-focused': isSelected,
                      })}
                      title={option.label}
                    >
                      {option.label}
                    </li>
                  )
                })}

                {!searchOptions.length ? (<li
                  title="No matches found"
                  className={style.noMatch}
                >
                  No matches found
                </li>) : null}
              </ul>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}

      {/* Error message below the select */}
      {error && <span className={style['error-message']}>{error}</span>}
    </label>
  );
}

export default FieldSelectTable;
