import { useFormik } from "formik";
import _, {
  cloneDeep,
  compact,
  defaults,
  entries,
  last,
  map,
  orderBy,
} from "lodash";
import React, { useEffect, useState } from "react";
import { IToastMessage, TooltipItem } from "../../interface";
import validateEmpty from "../../utils/validateEmpty";
import { InputType } from "../Table/components/Editor/Editor";
import InitTable, { Query } from "../Table/Table";
import ToastMessage from "../ToastMessage/ToastMessage";
import style from "./TooltipForm.module.scss";
import DiscardChangeConfirmModal from '../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';

const { Table, Column } = InitTable<TooltipItem>();

interface Props {
  tooltips: TooltipItem[];
  onSubmit: (tooltips: TooltipItem[]) => void;
}

function TooltipForm({ tooltips, onSubmit }: Props) {
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [initialValues, setInitialValues] = useState<TooltipItem[]>([]);
  const [query, setQuery] = useState<Query>();

  const requiredFields = (tooltip: TooltipItem) =>
    tooltip.enabled ? ["description"] : [];

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (values) =>
      map(values, (value) => {
        const _required = requiredFields(value);
        return defaults(validateEmpty(_.pick(value, _required)));
      }),
    onSubmit: () => {
      onSubmit(formik.values);
    },
  });

  useEffect(() => {
    setInitialValues(tooltips);
  }, [tooltips]);

  useEffect(() => {
    // ignore in coverage report because formik can not be null
    /* istanbul ignore else */
    if (formik) {
      formik.initialValues = initialValues;
    }
  }, [initialValues]);

  useEffect(() => {
    const tempData = cloneDeep(initialValues);
    const sort = last(entries(query?.order));

    setInitialValues(
      orderBy(
        tempData,
        [
          !sort || sort?.[0] === "title"
            ? (x) => compact([x.section, x.keyword]).join()
            : sort[0],
        ],
        [sort?.[1] ?? "asc"]
      )
    );
  }, [query]);

  return (
    <>
      <div className={style["main-content"]}>
        {
          // ignore in coverage report because toastMessage is always undefined
          /* istanbul ignore next */
          toastMessage?.visible && (
            <div className={style['toast']}>
              <ToastMessage
                status={toastMessage}
                className='validate-error'
                visiableHandler={(value) =>
                  setToastMessage({ ...toastMessage, visible: value })
                }
              />
            </div>
          )
        }
        <h3>Tool tip Contents</h3>
        <form
          className={style["main"]}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(formik.values);
            setInitialValues(formik.values);
          }}
        >
          {/* Main table */}
          <Table
            id="tooltipContent"
            rows={formik.values}
            onQueryChanged={setQuery}
            loading={false}
          >
            <Column
              id="title"
              label="Tool tip keyword/ section"
              sortable
              accessor={(tooltip) =>
                compact([tooltip.section, tooltip.keyword]).join(" - ")
              }
            />

            <Column
              label="Tool tip description"
              prop="description"
              editable
              editor={{
                type: InputType.TextArea,
                error: (__, idx) =>
                  formik.errors[idx as number]?.description ?? null,
              }}
              onChange={(value: string, tooltip) =>
                formik.setFieldValue(
                  `${[formik.values.indexOf(tooltip)]}.description`,
                  value
                )
              }
            />

            <Column
              label="On/ Off"
              prop="enabled"
              editable
              editor={{ type: InputType.Toggle }}
              onChange={(value: boolean, tooltip) => {
                formik.setFieldValue(
                  `${[formik.values.indexOf(tooltip)]}.enabled`,
                  value
                )
              }}
            />
          </Table>

          <div className={style["footer"]}>
            {/* Submit link,it change the flag of submit */}
            <div className={style["buttons"]}>
              <button type="submit" className="primary-button">
                Save
              </button>
              <button
                className="default-button"
                onClick={(e) => {
                  e.preventDefault();
                  formik.resetForm();
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
      <DiscardChangeConfirmModal
        initialData={initialValues}
        inputData={formik.values}
      />
    </>
  );
}

export default TooltipForm;
