import React, {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {SelectOption} from '../../interface';
import style from './FieldSelect.module.scss';
import newId from '../../utils/newId';
import {SEARCHABLELIMIT} from '../../constants';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import FieldReadonly from '../FieldReadOnly/FieldReadOnly';
import { SSelect } from '@avalara/skylab-sdk/react';

interface Props {
  labelClassName?: string;
  labelText: string;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  options: SelectOption[];
  onSelect: (value: SelectOption | undefined) => void;
  onMultipleSelect?: (value: SelectOption[]) => void;
  selectText?: string;
  force?: boolean;
  selectId?: number | string | boolean | null;
  selectIds?: (number | string | boolean | null)[];
  recommended?: boolean;
  multiple?: boolean;
  noSearch?: boolean;
  async?: boolean;
  onSInput?: (search: string) => void;
  hideNullOption?: boolean;
  isInReadOnlyMode?: boolean;
  disabledMessage?: string;
}

function FieldSelect(props: Props) {
  const {
    labelClassName,
    labelText,
    classnames,
    required,
    tooltipText,
    disabled,
    error,
    options,
    onSelect,
    onMultipleSelect,
    selectText,
    force,
    recommended,
    selectId,
    selectIds,
    multiple = false,
    noSearch = false,
    async = false,
    onSInput,
    hideNullOption,
    isInReadOnlyMode = false,
    disabledMessage
  } = props;

  const selectedMultipleItemsRef = useRef<SelectOption[]>([]);
  const [fieldSelectId, setFieldSelectId] = useState<string>();
  const [noSearchState, setNoSearchState] = useState(noSearch);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFieldSelectId(newId());
  }, []);

  useEffect(() => {
    setNoSearchState(async ? false : !_.gt(options.length, SEARCHABLELIMIT));
  }, [options, async]);

  const selectItem = (item: SelectOption) => {
    if (multiple) {
      if (
        item.value !== null &&
        !_.find(selectedMultipleItemsRef.current, {value: item.value})
      ) {
        selectedMultipleItemsRef.current.push(item);
      }
    }
  };

  const optionsWithSelected = useMemo(() => {
    let isSelected = false;
    const newOptions: SelectOption[] = options.map((option) => {
      let selected = false;
      if (selectId) {
        selected = option.value == selectId;
      } else if (selectText) {
        selected = option.label === selectText;
      } else if (selectIds) {
        selected = selectIds.indexOf(option.value) >= 0;
      }
      if (selected) {
        isSelected = true;
        selectItem(option);
      } else if (multiple) {
        selectedMultipleItemsRef.current = _.filter(
          selectedMultipleItemsRef.current,
          (item) => item.value !== option.value,
        );
      }
      return {
        ...option,
        value: option.value?.toString() || '',
        selected,
      };
    });
    if (!multiple && !force && (!newOptions.length || !!newOptions[0].value)) {
      newOptions.unshift({label: 'Select', value: '', selected: !isSelected});
    }
    return newOptions;
  }, [options, selectText, selectId, selectIds, force, multiple]);

  const selectionOptional = useMemo(() => {
    if (
      optionsWithSelected.length &&
      optionsWithSelected[0].value === '' &&
      optionsWithSelected[0].selected
    ) {
      return false;
    }
    return !force;
  }, [optionsWithSelected]);

  const selectedValueText = useMemo(() => {
    if (selectText) {
      return selectText;
    }
    if (
      optionsWithSelected.length
    ) {
      const selectedOption = _.find(optionsWithSelected, { selected: true });
      return selectedOption?.label || '';
    }
    return '';
  }, [optionsWithSelected, selectText]);

  if (isInReadOnlyMode) {
    return (
      <FieldReadonly
        className={classnames}
        labelText={labelText}
        required={required}
        tooltipText={tooltipText}
        value={selectedValueText === 'Select' ? '' : selectedValueText}
      />
    )
  }
  return (
    <div
      className={classNames('FieldSelect', style.container, classnames, {
        error,
        [style.hideNullOption]:
          hideNullOption &&
          optionsWithSelected.length &&
          optionsWithSelected[0].value === '',
      })}
      ref={ref}
    >
      {/* Lable of input,accept required and tooltip parameters */}
      {!!labelText && (
        <div
          className={classNames(
            style['label'],
            {[style['label-recommended']]: recommended},
            labelClassName,
          )}
        >
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Common}
            tooltipKey={tooltipText ? tooltipText : labelText}
          >
            <span>
              {labelText}
              {required && <i className={style['required']}>*</i>}
            </span>
          </TooltipWrapper>
          {recommended && <small>Recommended</small>}
        </div>
      )}
      <SSelect
        className={classNames({error})}
        disabled={disabled ? true : false}
        inputId={`FieldSelect-${fieldSelectId}`}
        id={`FieldSelectId-${fieldSelectId}`}
        multiple={multiple}
        showSelectionCount={false}
        selectionOptional={selectionOptional}
        noSearch={noSearchState}
        async={async}
        // eslint-disable-next-line
        optionsList={optionsWithSelected as any}
        title={disabledMessage ?? ''}
        onS-select={(e: CustomEvent) => {
          if (multiple) {
            selectItem(e.detail.item);
            if (onMultipleSelect) {
              onMultipleSelect(selectedMultipleItemsRef.current);
            }
          } else {
            onSelect(e.detail.item);
          }
        }}
        onS-deselect={(e: CustomEvent) => {
          if (
            !multiple &&
            optionsWithSelected.length &&
            optionsWithSelected[0].value === ''
          ) {
            onSelect(optionsWithSelected[0]);
          } else if (multiple) {
            selectedMultipleItemsRef.current = _.filter(
              selectedMultipleItemsRef.current,
              (item) => item.value !== e.detail.item.value,
            );
            if (onMultipleSelect) {
              onMultipleSelect(selectedMultipleItemsRef.current);
            }
          }
        }}
        onS-open={() => {
          let visuallyFocusedItemValue: string | number | boolean | undefined = undefined;
          if (selectId) {
            visuallyFocusedItemValue = selectId;
          } else if (selectText) {
            visuallyFocusedItemValue = selectText;
          }
          if (visuallyFocusedItemValue !== undefined) {
            ref.current
              // eslint-disable-next-line
              ?.querySelector<any>(`s-select#FieldSelectId-${fieldSelectId}`)
              ?.setState({
                visuallyFocusedItemValue,
              });
          }
        }}
        onS-input={(e: CustomEvent) => {
          if (onSInput) {
            onSInput(e.detail.inputValue);
          }
        }}
        onS-close={() => {
          ref.current && (ref.current as HTMLElement).click();
        }}
      />
      {/* Error message below the select */}
      {error && <span className={style['error-message']}>{error}</span>}
    </div>
  );
}

export default FieldSelect;
