import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import ModalContextProvider from './components/ModalContextProvider/ModalContextProvider';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import './style/styles.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// eslint-disable-next-line
if ((window as any).dayjs) {
  // eslint-disable-next-line
  (window as any).dayjs.locale('en');
}

root.render(
  <Provider store={store}>
    <ModalContextProvider>
      <App />
    </ModalContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
