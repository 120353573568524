import React, { useMemo } from 'react';
import classNames from 'classnames';
import style from './FormManagementSearch.module.scss';
import FieldSelect from '../FieldSelect/FieldSelect';
import FieldInput from '../FieldInput/FieldInput';
import IconSearch from '../../images/icon-search.svg';
import { DepreciationScheduleCollection, IndexTableCollection, SelectOption } from '../../interface';
import _ from 'lodash';
import { IFormManagementDefaultCollection } from '../../interface/IFormManagementDefaultCollection';
import { IBackendFormMapping } from '../../interface/IBackendFormMapping';
import { IBackendFormInfo } from '../../interface/IBackendFormInfo';

interface Props {
  className?: string;
  setVisibleFilterPanel?: React.Dispatch<React.SetStateAction<"filter" | null>>;
  depreciationScheduleCollections?: DepreciationScheduleCollection[];
  formList?: IBackendFormInfo[];
  indexTableCollections?: IndexTableCollection[];
  searchStandardAssetClass?: string;
  setSearchStandardAssetClass?: React.Dispatch<React.SetStateAction<string>>;
  formMappingsList?: IBackendFormMapping[];
  updateUnsavedFormMappingsList?: (newValues: IBackendFormMapping[]) => void;
  shouldShowDefaultForm?: boolean;
  isInReadOnlyMode?: boolean;
  formikEditFormValues?: IFormManagementDefaultCollection;
  updateFormValue?: (key: string, value: string | number | null | boolean) => void;
  hideTableFilter?: boolean;
}

function FormManagementSearch({
  className,
  setVisibleFilterPanel,
  depreciationScheduleCollections,
  indexTableCollections,
  searchStandardAssetClass,
  setSearchStandardAssetClass,
  formMappingsList,
  updateUnsavedFormMappingsList,
  shouldShowDefaultForm,
  isInReadOnlyMode,
  formList,
  formikEditFormValues,
  updateFormValue,
  hideTableFilter
}: Props) {
  const depreciationScheduleCollectionOptions: SelectOption[] = useMemo(() => {
    const newOptions = (depreciationScheduleCollections ?? []).map(item => ({
      value: item.id,
      label: item.name,
      name: item.name
    }))
    return newOptions;
  }, [depreciationScheduleCollections]);

  const formListOptions: SelectOption[] = useMemo(() => {
    const newOptions = (formList ?? []).map(item => ({
      value: item.id,
      label: item.name,
      name: item.name
    }))
    return newOptions;
  }, [formList]);

  const defaultIndexCollectionOptions = useMemo(() => {
    const newOptions = (indexTableCollections ?? []).map(item => ({
      value: item.id,
      label: item.name,
      name: item.name
    }))
    return newOptions;
  }, [indexTableCollections]);
  const selectedDefaultDepreciationCollection = useMemo(() => {
    if (!formikEditFormValues?.defaultDepreciationCollection) {
      return null;
    }
    return _.find(depreciationScheduleCollections, { id: formikEditFormValues?.defaultDepreciationCollection });
  }, [
    depreciationScheduleCollections,
    formikEditFormValues?.defaultDepreciationCollection
  ])

  const resetAllDepreciationScheduleDropdowns = (defaultDepreciationCollection: number | null) => {
    const allEffectedData = _.filter(formMappingsList ?? [], (item) => {
      return !!item.depreciationScheduleId;
    }).map((item) => ({
      ...item,
      depreciationScheduleId: null
    }));
    if (allEffectedData.length > 0) {
      updateUnsavedFormMappingsList?.(allEffectedData);
    }
    if (defaultDepreciationCollection) {
      const matchValue = _.find(depreciationScheduleCollections, { id: defaultDepreciationCollection });
      if (matchValue && matchValue.type === 'Composite') {
        // Clear the value and disable defaultIndexCollection dropdown
        // if the selected Default Depreciation Schedule Collection is Composite type
        updateFormValue?.(
          'defaultIndexCollection',
          null,
        );
        resetAllIndexTableDropdowns()
      }
    }
  };

  const resetAllIndexTableDropdowns = () => {
    const allEffectedData = _.filter(formMappingsList ?? [], (item) => {
      return !!item.indexTableId;
    }).map((item) => ({
      ...item,
      indexTableId: null
    }));
    if (allEffectedData.length > 0) {
      updateUnsavedFormMappingsList?.(allEffectedData);
    }
  };

  const resetAllFromGroupDropdowns = () => {
    const allEffectedData = _.filter(formMappingsList ?? [], (item) => {
      return !!item.formGroupId;
    }).map((item) => ({
      ...item,
      formGroupId: null
    }));
    if (allEffectedData.length > 0) {
      updateUnsavedFormMappingsList?.(allEffectedData);
    }
  };

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'FormManagementSearch d-flex flex-column'
      )
    }>
      <div
        className={
          classNames(
            'd-flex align-items-start gap-10 full-width',
            {
              'align-items-end': isInReadOnlyMode
            }
          )
        }
      >
        {!hideTableFilter ? (<FieldInput
          icon={<img src={IconSearch} />}
          labelText=''
          name='searchStandardAssetClass'
          placeholder='Search Standard Asset Class..'
          value={searchStandardAssetClass ?? ''}
          onChange={(value) => {
            setSearchStandardAssetClass?.(value);
          }}
          classnames={classNames('flex-1', style.FieldInput, {
            [style.isInReadOnlyMode]: isInReadOnlyMode
          })}
        />) : null}

        {shouldShowDefaultForm ? (<FieldSelect
          labelText='Default Form'
          options={formListOptions}
          selectId={formikEditFormValues?.defaultForm}
          onSelect={(value) => {
            updateFormValue?.(
              'defaultForm',
              value?.value ? _.toNumber(value?.value) : null
            );
            resetAllFromGroupDropdowns();
          }}
          classnames='flex-1'
          isInReadOnlyMode={isInReadOnlyMode}
        />) : null}

        <FieldSelect
          labelText='Default Depreciation Collection'
          options={depreciationScheduleCollectionOptions}
          selectId={formikEditFormValues?.defaultDepreciationCollection}
          onSelect={(value) => {
            updateFormValue?.(
              'defaultDepreciationCollection',
              value?.value ? _.toNumber(value?.value) : null,
            );
            resetAllDepreciationScheduleDropdowns(value?.value as number);
          }}
          classnames='flex-1'
          isInReadOnlyMode={isInReadOnlyMode}
        />

        <div className={classNames('d-flex align-items-end gap-30', style.blockColumn3)}>
          <FieldSelect
            labelText='Default Index Collection'
            options={defaultIndexCollectionOptions}
            selectId={formikEditFormValues?.defaultIndexCollection}
            onSelect={(value) => {
              updateFormValue?.(
                'defaultIndexCollection',
                value?.value ? _.toNumber(value?.value) : null
              );
              resetAllIndexTableDropdowns();
            }}
            classnames='flex-1'
            disabled={(!!selectedDefaultDepreciationCollection) && selectedDefaultDepreciationCollection.type === 'Composite'}
            isInReadOnlyMode={isInReadOnlyMode}
          />

          {!hideTableFilter ? (<button
            className="default-button filter-button"
            onClick={() => setVisibleFilterPanel?.("filter")}
          >
            <i></i>Filter
          </button>) : null}
        </div>
      </div>
    </div>
  );
}

export default FormManagementSearch;