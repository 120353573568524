import React, {MutableRefObject, useMemo, useState} from 'react';
import { SUploader } from '@avalara/skylab-sdk/react'
import _ from 'lodash';
import classNames from 'classnames';
import style from './AssetsDropzone.module.scss';
import {getAssetsTemplate, getErrorMessage, uploadFile} from '../../services';
import exportXLSX from '../../utils/exportFile';
import useMappedResult from '../../hook/useMappedResult';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../enums';
import {LoaderIndicator} from '../Loader/Loader';
import {ToastMessageRefType} from '../ToastMessage/ToastMessage';
import { SAttachFilesData } from '@avalara/skylab-sdk';

interface Props {
  uploadError: (value: string) => void;
  type: string | null;
  toastRef: MutableRefObject<ToastMessageRefType | null>;
  uploadSuccess: () => void;
}

const maxFileSize = 75 * 1024 * 1024;

function AssetsDropZone(props: Props) {
  const {type, uploadError, toastRef, uploadSuccess} = props;
  const [files, setfiles] = useState<File[]>([]);

  const title = useMemo(
    () =>
      type
        ? `Drag & drop ${_.lowerCase(type)} file here`
        : `Drag & drop file here`,
    [type],
  );

  const onChange = (value: File[]) => {
    if (!value.length || value[0].size > maxFileSize) {
      return;
    }
    setfiles(value);
  };
  const inputProps = {
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,.xls,.csv',
    multiple: false,
    tabIndex: -1,
    type: 'file',
  };
  const [downloading, setDownloading] = useState(false);
  const download = () => {
    if (sessionStorage.getItem('assetType')) {
      const res = getAssetsTemplate(
        sessionStorage.getItem('assetType') as string,
      );
      setDownloading(true);
      exportXLSX(res, () => {
        setDownloading(false);
      });
    }
  };
  const {fileRows} = useMappedResult(files[0]);
  const [uploading, setUploading] = useState(false);
  const upload = () => {
    if (_.isEmpty(fileRows)) {
      uploadError(`The uploaded file contains no data.`);
      return;
    }
    if (sessionStorage.getItem('assetType')) {
      setUploading(true);
      uploadFile({
        file: files[0],
        type: 'Asset',
        assetType: sessionStorage.getItem('assetType') as string,
      })
        .then((d) => {
          const errorMessage = getErrorMessage(d);
          if (errorMessage) {
            toastRef.current?.showErrorToast(errorMessage);
          } else {
            uploadSuccess();
          }

          setUploading(false);
          setfiles([]);
        })
        .catch((e) => {
          toastRef.current?.showErrorToast(getErrorMessage(e));
          setUploading(false);
          setfiles([]);
        });
    }
  };
  const cancel = () => {
    setfiles([]);
  };

  return (
    <div className={classNames(style.container)}>
      {/* Uploader */}
      <SUploader
        onS-attach={(e: CustomEvent) => {
          onChange(e.detail.files);
        }}
        className={files.length ? `filled` : 'non-filled'}
        i18n={{
          'uploader.dragDropFileSingle': title,
          'uploader.dragDropFilesMultiple': title,
          'uploader.chooseFileSingle': files.length
            ? 'Change file'
            : 'Choose file',
          'uploader.chooseFilesMultiple': files.length
            ? 'Change file'
            : 'Choose file',
        }}
        {...inputProps}
      >
        <div slot='guidelines'>
          <div className={style['placeholder']}>
            <small>File must be .XLS, .XLSX, or .CSV and under 75MB</small>
          </div>
          {!_.isEmpty(files) && (
            <ul className={style['list']}>
              {files.map((f, i) => (
                <li key={i}>
                  <p>
                    <i className={style['icon']}></i>
                    {f['name']}
                  </p>
                  <i role='button' className='icon-remove' onClick={cancel}></i>
                </li>
              ))}
            </ul>
          )}
          <div className={style['action']}>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.PageAction}
              tooltipKey='Upload'
            >
              <button
                className='primary'
                onClick={upload}
                disabled={uploading || _.isEmpty(files)}
                type={'button'}
              >
                Upload file
                {uploading ? (
                  <LoaderIndicator className='button-loading' loading={true} />
                ) : null}
              </button>
            </TooltipWrapper>
          </div>
        </div>
      </SUploader>
      {/* Buttons */}
      <div className={style['buttons']}>
        <div className={style['operate']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Download template and formatting guide'
          >
            <button
              className='button secondary'
              onClick={download}
              disabled={downloading}
            >
              Download template and formatting guide
              {downloading ? (
                <LoaderIndicator className='button-loading' loading={true} />
              ) : null}
            </button>
          </TooltipWrapper>
        </div>
      </div>
    </div>
  );
}

export default AssetsDropZone;
