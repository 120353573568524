import React, { useMemo } from "react";
import classNames from "classnames";
import _ from "lodash";
import { SelectOption } from "../../interface";
import style from "./FieldRelatedSelect.module.scss";
import { SEARCHABLELIMIT } from "../../constants";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import { TOOLTIP_SECTIONS } from "../../enums";
import { SSelect } from "@avalara/skylab-sdk/react";
import { SSelectItem } from "@avalara/skylab-sdk";

interface Props {
  labelText: string;
  value?: number;
  onChange: (value: number | undefined) => void;
  classnames?: string;
  required?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  error?: string;
  name?: string;
  maxLength?: number;
  recommended?: boolean;
  placeholder?: string;
  options: SelectOption[];
  shouldAllowNull?: boolean;
}

function FieldRelatedSelect(props: Props) {
  const {
    labelText,
    value,
    onChange,
    classnames,
    required,
    tooltipText,
    disabled,
    error,
    recommended,
    options,
    shouldAllowNull = false,
  } = props;

  const uniqueId = _.uniqueId();
  const optionsWithSelected = useMemo(() => {
    const newOptions = _.map(options, (option) => ({
      ...option,
      selected: option.value == value,
      originLabel: option.label,
      label: `${option.label ? option.label : ""} ${
        _.isEqual(option.label, "All") ? "" : " | "
      } ${option.name}`,
      value: option.value?.toString() || '',
    }));

    if (value) {
      return newOptions;
    }

    if (shouldAllowNull) {
      return _.map(newOptions, (option) => {
        if (option.value) {
          return option;
        } else {
          return { ...option, selected: true };
        }
      });
    }

    return _.concat(
      [
        {
          label: "Select",
          value: '',
          selected: _.every(newOptions, "selected"),
          originLabel: "Select",
        },
      ],
      newOptions
    );
  }, [options, value, shouldAllowNull]);

  return (
    <label
      className={classNames("form-control", style["form-control"], classnames, {
        error: error,
      })}
    >
      {/* Lable of input,accept required and tooltip parameters */}
      <div
        className={classNames(style["label"], {
          [style["label-recommended"]]: recommended,
        })}
      >
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.Common}
          tooltipKey={tooltipText ? tooltipText : labelText}
        >
          <span>
            {labelText}
            {required && <i className={style["required"]}>*</i>}
          </span>
        </TooltipWrapper>
        {recommended && <small>Recommended</small>}
      </div>

      <div className={style["input-wrapper"]}>
        <SSelect
          className={classNames({ error })}
          disabled={disabled ? true : false}
          // eslint-disable-next-line
          optionsList={optionsWithSelected as any}
          inputId={`FieldSelect-${uniqueId}`}
          id={`FieldSelectId-${uniqueId}`}
          noSearch={_.gt(options.length, SEARCHABLELIMIT) ? false : true}
          onS-select={(e: CustomEvent) => {
            onChange(_.get(e.detail.item, "value"));
          }}
          onS-open={() => {
            let visuallyFocusedItemValue: string | number | undefined =
              undefined;
            if (value) {
              visuallyFocusedItemValue = value;
            }
            if (visuallyFocusedItemValue !== undefined) {
              document
                // eslint-disable-next-line
                .querySelector<any>(`s-select#FieldSelectId-${uniqueId}`)
                ?.setState({
                  visuallyFocusedItemValue,
                });
            }
          }}
        />
        <div className={style["cover"]}>
          {_.get(
            _.find(optionsWithSelected, (item) => item.value == value?.toString()),
            "originLabel"
          )}
        </div>
        <div className={style["readonly"]}>
          {
            _.find(optionsWithSelected, (item) => item.value == value?.toString())
              ?.name
          }
        </div>
      </div>
      {/* Error message below the select */}
      {error && <span className={style["error-message"]}>{error}</span>}
    </label>
  );
}

export default FieldRelatedSelect;
