import { SFiltersPanel } from '@avalara/skylab-sdk/react';
import {
  groupBy,
  isArray,
  isBoolean,
  isEmpty,
  isObject,
  map,
  omit,
  size,
  toString,
} from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import SideBarFlyoutWithInputs from "../../../SideBarFlyoutWithInputs/SideBarFlyoutWithInputs";
import { getColKey } from "../../utils";
import { ColumnConfig, ColumnFilters } from "../Column";
import Editor from "../Editor/Editor";
import style from "./FilterPanel.module.scss";

interface Props<T> {
  id: string;
  open: boolean;
  onClose: () => void;
  columns: ColumnConfig<T>[];
  defaultFilterable: boolean;
  filters: ColumnFilters;
  onChange: (updated: ColumnFilters) => void;
}

export default function FilterPanel<T>({
  id,
  columns,
  open,
  onClose,
  defaultFilterable,
  filters,
  onChange,
}: Props<T>) {
  const [draftFilters, setDraftFilters] = useState<ColumnFilters>({});

  useEffect(() => {
    if (!open) setDraftFilters({ ...filters });
  }, [open, filters]);

  const groupedColumns = useMemo(
    () =>
      groupBy(
        columns.filter((x) => x.props.filterable ?? defaultFilterable),
        (x) => (isArray(x.props.label) ? x.props.label[0] : x.props.label)
      ),
    [columns]
  );

  return (
    <SideBarFlyoutWithInputs isOpen={open} dismiss={onClose}>
      <SFiltersPanel
        className={style["filter-panel"]}
        open={open}
        id={id}
        onS-dismiss={() => onClose()}
        onS-reset={() => setDraftFilters({})}
        onS-apply={() => {
          onChange({ ...draftFilters });
          onClose();
        }}
      >
        <div slot="filters-panel-content">
          <span className={style.groupLabel}>
            {size(draftFilters)} Filter{size(draftFilters) === 1 ? "" : "s"}{" "}
            Selected
          </span>

          {map(groupedColumns, (group, label) => (
            <div className={style.group} key={label}>
              <label
                className={
                  isArray(group[0].props.label) && group[0].props.label[1]
                    ? style.groupLabel
                    : style.label
                }
              >
                {label}
              </label>

              {group.map((col) => {
                const colKey = getColKey(col.props);

                return (
                  <Editor
                    key={colKey}
                    label={
                      col.props.editor?.label ??
                      ((isArray(col.props.label) && col.props.label[1]) || "")
                    }
                    value={draftFilters[colKey]?.value}
                    selectedOptionLabels={draftFilters[colKey]?.label}
                    config={col.props.editor}
                    overrides={
                      !isBoolean(col.props.filterable)
                        ? col.props.filterable
                        : undefined
                    }
                    onChange={(value, _label) => {
                      const filterIsValid = isObject(value)
                        ? !isEmpty(value)
                        : !!toString(value);
                      const newFilters = filterIsValid
                        ? { ...draftFilters, [colKey]: { value, label: _label } }
                        : omit(draftFilters, colKey);

                      setDraftFilters(newFilters);
                    }}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </SFiltersPanel>
    </SideBarFlyoutWithInputs>
  );
}
