import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import style from './FormManagement.module.scss';
import Divider from '../../../components/Divider/Divider';
import FormManagementSearch from '../../../components/FormManagementSearch/FormManagementSearch';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import { TOOLTIP_SECTIONS } from '../../../enums';
import FormManagementTable from '../../../components/FormManagementTable/FormManagementTable';
import { DepreciationScheduleCollection, IToastMessage, IndexTableCollection } from '../../../interface';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import FormManagementInfo from '../../../components/FormManagementInfo/FormManagementInfo';
import Loader, { LoaderIndicator } from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import { getDepreciationScheduleCollections, getErrorMessage, getFormInfo, getIndexTableCollections, getReturnForms, updateFormInfo, updateMappingInfo } from '../../../services';
import _ from 'lodash';
import { FormikProps, FormikValues, useFormik } from 'formik';
import validateEmpty from '../../../utils/validateEmpty';
import { IBackendFormInfo } from '../../../interface/IBackendFormInfo';
import { IFormManagementData } from '../../../interface/IFormManagementData';
import { IBackendFormMapping } from '../../../interface/IBackendFormMapping';

interface Props {
  className?: string;
}

interface IFormDataSearch {
  taxYear?: number | null;
  state?: number | null;
  form?: number | null;
}
const initialFormValues: IFormManagementData = {
  formName: '',
  taxFormCatalogId: '',
  setFormAsDefaultForState: false,
  defaultDepreciationCollection: null,
  defaultIndexCollection: null
};
const initialSearchValues: IFormDataSearch = {
  taxYear: null,
  state: null,
  form: null,
};

// Define the required fields
const requiredFieldSearchForm = [
  'taxYear',
  'state',
  'form',
];

function FormManagement({ className }: Props) {
  const [visibleFilterPanel, setVisibleFilterPanel] = useState(null as "filter" | null);
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [initialValues, setInitialValues] = useState<IFormManagementData>(
    initialFormValues,
  );
  const [searchStandardAssetClass, setSearchStandardAssetClass] = useState('');
  const [formList, setFormList] = useState<IBackendFormInfo[]>([]);
  const [backendFormMappingsList, setBackendFormMappingsList] = useState<IBackendFormMapping[]>([]);
  const [formMappingsList, setFormMappingsList] = useState<IBackendFormMapping[]>([]);
  const [unsaveformMappingsList, setUnsaveformMappingsList] = useState<IBackendFormMapping[]>([]);
  const [depreciationScheduleCollections, setDepreciationScheduleCollections] = useState<DepreciationScheduleCollection[]>([]);
  const [indexTableCollections, setIndexTableCollections] = useState<IndexTableCollection[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [
    fetchingDepreciationScheduleCollections,
    setFetchingDepreciationScheduleCollections,
  ] = useState(false);
  const [fetchingReturnForms, setFetchingReturnForms] = useState(false);
  const [fetchingIndexTableCollections, setFetchingIndexTableCollections] =
    useState(false);
  const [fetchingFormInfo, setFetchingFormInfo] =
    useState(false);
  const showSuccessMessage = () => {
    window.scrollTo(0, 0);
    setToastMessage({
      message: 'Form is saved successfully.',
      visible: true,
      type: 'success',
    });
  }
  const showErrorMessage = (message: string) => {
    window.scrollTo(0, 0);
    setToastMessage({
      message,
      visible: true,
      type: 'error',
    });
  }

  const updateUnsavedFormMappingsList = (newValues: IBackendFormMapping[]) => {
    setFormMappingsList(formMappingsList.map(item => {
      const matchItem = _.find(newValues, {
        commonClassId: item.commonClassId
      });
      if (matchItem) {
        return {
          ...item,
          ...matchItem
        };
      } else {
        return item;
      }
    }));
    const newUnsaveformMappingsList = unsaveformMappingsList.map(item => {
      const matchIndex = _.findIndex(newValues, {
        commonClassId: item.commonClassId
      });
      if (matchIndex >= 0) {
        const newValue = newValues[matchIndex];
        newValues.splice(matchIndex, 1);
        return {
          ...item,
          ...newValue
        };
      } else {
        return item;
      }
    });
    const results = [
      ...newUnsaveformMappingsList,
      ...newValues
    ];
    setUnsaveformMappingsList(results);
  }
  const removeUnsavedFormMappingsList = (newValues: IBackendFormMapping[]) => {
    setBackendFormMappingsList(backendFormMappingsList.map(item => {
      const matchItem = _.find(newValues, {
        commonClassId: item.commonClassId
      });
      if (matchItem) {
        return {
          ...item,
          ...matchItem
        };
      } else {
        return item;
      }
    }))
    setFormMappingsList(formMappingsList.map(item => {
      const matchItem = _.find(newValues, {
        commonClassId: item.commonClassId
      });
      if (matchItem) {
        return {
          ...item,
          ...matchItem
        };
      } else {
        return item;
      }
    }));
    setUnsaveformMappingsList(_.filter(unsaveformMappingsList, (item) => {
      return !_.find(newValues, { commonClassId: item.commonClassId })
    }));
  }

  const saveMappingInfo = (newValues: IBackendFormMapping[]) => {
    setIsSaving(true);
    updateMappingInfo(newValues.map((item) => ({
      ...item,
      stateId: formikSearchForm.values.state,
      taxYear: formikSearchForm.values.taxYear,
      assessorId: null,
      formId: formikSearchForm.values.form,
      depreciationScheduleCollectionId: formikEditForm.values.defaultDepreciationCollection ?? null,
      indexTableCollectionId: formikEditForm.values.defaultIndexCollection ?? null,
    })))
      .then(
        () => {
          removeUnsavedFormMappingsList(newValues);
          setIsSaving(false);
          showSuccessMessage();
        },
      )
      .catch((e) => {
        setIsSaving(false);
        showErrorMessage(getErrorMessage(e));
      });
  }

  const formikEditForm: FormikProps<IFormManagementData> = useFormik<FormikValues>({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values: IFormManagementData) => {
      setIsSaving(true);
      updateFormInfo(formikSearchForm.values.form ?? 0, {
        name: values.formName ?? '',
        internalId: values.taxFormCatalogId ?? '',
        isStateDefaultForm: values.setFormAsDefaultForState ?? false,
        defaultDepreciationScheduleCollectionId: values.defaultDepreciationCollection ?? null,
        defaultIndexTableCollectionId: values.defaultIndexCollection ?? null,
        mappings: unsaveformMappingsList.map((item) => ({
          ...item,
          stateId: formikSearchForm.values.state,
          taxYear: formikSearchForm.values.taxYear,
          assessorId: null,
          formId: formikSearchForm.values.form,
          depreciationScheduleCollectionId: values.defaultDepreciationCollection ?? null,
          indexTableCollectionId: values.defaultIndexCollection ?? null,
        })),
      })
        .then(
          () => {
            setBackendFormMappingsList([...formMappingsList]);
            setIsSaving(false);
            setUnsaveformMappingsList([]);
            setInitialValues(values);
            showSuccessMessage();
          },
        )
        .catch((e) => {
          setIsSaving(false);
          showErrorMessage(getErrorMessage(e));
        });
    },
  });

  const formikSearchForm: FormikProps<IFormDataSearch> = useFormik<FormikValues>({
    initialValues: initialSearchValues,
    enableReinitialize: true,
    validate: (values) => {
      const errors = validateEmpty(_.pick(values, requiredFieldSearchForm));
      return errors;
    },
    onSubmit: () => {
      console.log('formikSearchForm onSubmit');
    },
  });
  useEffect(() => {
    const isChanged = (!!formikSearchForm.values.form) && (
      !_.isEqual(
        initialValues,
        formikEditForm.values
      ) ||
      unsaveformMappingsList.length > 0
    );
    setIsFormChanged(isChanged);
  }, [
    formikEditForm.values,
    formikSearchForm.values.form,
    initialValues,
    unsaveformMappingsList
  ]);

  useEffect(() => {
    const formsQuery = {
      stateId: formikSearchForm.values.state ?? null,
      taxYear: formikSearchForm.values.taxYear ?? null,
    }
    if (
      formsQuery.stateId &&
      formsQuery.taxYear
    ) {

      setFetchingDepreciationScheduleCollections(true);
      getDepreciationScheduleCollections({
        page: 0,
        stateIds: formsQuery.stateId.toString(),
        taxYear: formsQuery.taxYear
      })
        .then((res) => {
          setDepreciationScheduleCollections(
            _.get(res, 'data.items') as DepreciationScheduleCollection[]
          );
          setFetchingDepreciationScheduleCollections(false);
        })
        .catch(() => {
          setFetchingDepreciationScheduleCollections(false);
        });

      setFetchingReturnForms(true);
      getReturnForms({
        page: 0,
        ...formsQuery
      })
        .then((res) => {
          const items: IBackendFormInfo[] = _.get(res, 'data.items');
          setFormList(items);

          setFetchingReturnForms(false);
        })
        .catch(() => {
          setFetchingReturnForms(false);
        });

      setFetchingIndexTableCollections(true);
      getIndexTableCollections({
        page: 0,
        stateIds: formsQuery.stateId.toString(),
        taxYear: formsQuery.taxYear
      })
        .then((res) => {
          setIndexTableCollections(
            _.get(res, 'data.items') as IndexTableCollection[]
          );
          setFetchingIndexTableCollections(false);
        })
        .catch(() => {
          setFetchingIndexTableCollections(false);
        });
    }
  }, [
    formikSearchForm.values.state,
    formikSearchForm.values.taxYear
  ]);

  useEffect(() => {
    let selectedForm: IBackendFormInfo | undefined = undefined
    if (formikSearchForm.values.form) {
      selectedForm = _.find(formList, form => form.id == formikSearchForm.values.form);
    }

    const resetFormValues = () => {
      setInitialValues(initialFormValues)
      setFormMappingsList([]);
      setUnsaveformMappingsList([]);
    }

    if (selectedForm) {
      setFetchingFormInfo(true);
      getFormInfo(selectedForm.id)
        .then((res) => {
          setInitialValues({
            formName: res.data.name,
            taxFormCatalogId: res.data.internalId,
            setFormAsDefaultForState: res.data.isStateDefaultForm,
            defaultDepreciationCollection: res.data.defaultDepreciationScheduleCollectionId,
            defaultIndexCollection: res.data.defaultIndexTableCollectionId
          });
          const mappings = (res.data.mappings ?? []).map(item => ({
            ...item,
            taxable: item.taxable ?? false,
            reportable: item.reportable ?? false,
          }));
          setFormMappingsList(mappings);
          setBackendFormMappingsList(mappings);
          setUnsaveformMappingsList([]);
          setFetchingFormInfo(false);
        })
        .catch(() => {
          resetFormValues();
          setFetchingFormInfo(false);
        });

    } else {
      resetFormValues();
    }
  }, [
    formikSearchForm.values.form
  ]);

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'FormManagement d-flex flex-column')
    }>
      {toastMessage?.visible && (
        <div className={style.toast}>
          <ToastMessage
            className='successful'
            status={toastMessage}
            visiableHandler={(value) =>
              setToastMessage({ ...toastMessage, visible: value })
            }
          />
        </div>
      )}
      <span className={style.textTitle}>Form Management</span>

      <Divider className={style.divider1} />
      <FormManagementInfo
        formList={formList}
        formikEditForm={formikEditForm}
        formikSearchForm={formikSearchForm}
      />
      {formikSearchForm.values.form && (
        <>
          <FormManagementSearch
            setVisibleFilterPanel={setVisibleFilterPanel}
            className={style.FormManagementSearch}
            depreciationScheduleCollections={depreciationScheduleCollections}
            indexTableCollections={indexTableCollections}
            searchStandardAssetClass={searchStandardAssetClass}
            setSearchStandardAssetClass={setSearchStandardAssetClass}
            formMappingsList={formMappingsList}
            updateUnsavedFormMappingsList={updateUnsavedFormMappingsList}
            formikEditFormValues={formikEditForm.values}
            updateFormValue={(key, value) => formikEditForm.setFieldValue(
              key,
              value,
            )}
          />
          <FormManagementTable
            visibleFilterPanel={visibleFilterPanel}
            setVisibleFilterPanel={setVisibleFilterPanel}
            onSaveDataSuccess={showSuccessMessage}
            formMappingsList={formMappingsList}
            searchStandardAssetClass={searchStandardAssetClass}
            formId={formikSearchForm.values.form}
            depreciationScheduleCollectionId={formikEditForm.values.defaultDepreciationCollection}
            indexTableCollectionId={formikEditForm.values.defaultIndexCollection}
            updateUnsavedFormMappingsList={updateUnsavedFormMappingsList}
            saveMappingInfo={saveMappingInfo}
          />
          <Divider className={style.divider2} />
        </>
      )}
      <Divider className={style.divider2} />

      <div className='d-flex gap-20'>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageAction}
          tooltipKey='Save'
        >
          <button
            className='primary'
            data-testid="btn-save-form"
            disabled={isSaving || !isFormChanged}
            onClick={() => {
              formikEditForm.submitForm();
            }}
          >
            Save
            {isSaving ? (
              <LoaderIndicator
                className='button-loading'
                loading={true}
              />
            ) : null}
          </button>
        </TooltipWrapper>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageAction}
          tooltipKey='Cancel'
        >
          <button
            className='secondary'
            onClick={() => {
              setFormMappingsList(backendFormMappingsList);
              setUnsaveformMappingsList([]);
              formikEditForm.setValues(initialValues, false);
              formikSearchForm.setValues(initialSearchValues, false);
            }}
          >
            Cancel
          </button>
        </TooltipWrapper>
      </div>

      <DiscardChangeConfirmModal
        initialData={1}
        inputData={isFormChanged ? 2 : 1}
      />

      <Loader
        isOpen={
          fetchingDepreciationScheduleCollections ||
          fetchingReturnForms ||
          fetchingIndexTableCollections ||
          fetchingFormInfo ||
          isSaving
        }
      />
    </div>
  );
}

export default FormManagement;