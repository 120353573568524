import React, {useState, useEffect} from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import {FormikValues, useFormik} from 'formik';
import FieldInput from '../../../components/FieldInput/FieldInput';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import FieldDate from '../../../components/FieldDate/FieldDate';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import FieldRelatedSelect from '../../../components/FieldRelatedSelect/FieldRelatedSelect';
import validateEmpty from '../../../utils/validateEmpty';
import {
  AssetField,
  IErrorBase,
  IToastMessage,
  ReduxState,
  SelectOption,
  State,
} from '../../../interface';
import style from './VehicleSetup.module.scss';
import {
  getAllAssessors,
  getAllAssetClass,
  getAllLegalEntities,
  getAllProperties,
  getAsset,
  getCompanies,
  getUploadFile,
  setAssets,
  updateAsset,
  updateUploadFile,
} from '../../../services';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import FieldInputWithOptions from '../../../components/FieldInputWithOptions/FieldInputWithOptions';
import removeAssetsEmptyFields from '../../../utils/removeAssetsEmptyFields';
import {AxiosResponse} from 'axios';
import {connect} from 'react-redux';
import {useDebounce} from 'use-debounce';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';
import DiscardChangeConfirmModal from '../../../components/DiscardChangeConfirmModal/DiscardChangeConfirmModal';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import {TOOLTIP_SECTIONS} from '../../../enums';
import {useAppDispatch} from '../../../redux/store';
import {getDashboardInfo} from '../../../redux/features/dashboard-info-slice';
import useDashboardInfo from '../../../hook/useDashboardInfo';
import useCommonEffects from '../../../utils/assetSetup';
import useSelectedSystemTaxYear from '../../../hook/useSelectedSystemTaxYear';

// Init formik values
const newInit = {
  showOptional: false,
  disposed: false,
  isAddition: false,
  companyId: null,
  legalEntityId: null,
  propertyId: null,
  assessorAccountId: null,
  number: '',
  class: '',
  acquisitionDate: '',
  taxYear: null,
  alternateAssetId: '',
  description: '',
  notes: '',
  type: 'Vehicle',
  stateId: null,
  vehicle: {
    glCodeAlternateAccountNumber: '',
    life: '',
    companyCostCenter: '',
    rebookDate: '',
    rebookCost: null,
    historicalNumber: '',
    ltdDepreciation: null,
    originalCost: '0',
    renderedMarketValue: null,
    vehicleMake: '',
    vehicleModel: '',
    vehicleVin: '',
    vehicleYear: null,
  },
};

// Define the required fields
const requiredField = [
  'stateId',
  'companyId',
  'legalEntityId',
  'propertyId',
  'assessorAccountId',
  'number',
  'class',
  'acquisitionDate',
  'taxYear',
  'vehicle.originalCost',
];

const decimalField = [
  'taxYear',
  'vehicle.rebookCost',
  'vehicle.ltdDepreciation',
  'vehicle.originalCost',
  'vehicle.renderedMarketValue',
  'vehicle.vehicleYear',
];

interface Props {
  states: State[];
}

function setOptions(d: AxiosResponse) {
  return _.map(d.data.items, (item) => ({
    value: item.id,
    label: item.name,
    name: item.number,
    item: item,
  }));
}

function VehicleSetup(props: Props) {
  const appDispatch = useAppDispatch();
  const {id} = useParams();
  const {states} = props;
  const [navBlock, setNavBlock] = useState<boolean>();
  const uploadMatch = useMatch('/assets/editUploadVehicleSetup/*');
  const [searchParams] = useSearchParams();
  const assetId = searchParams.get('itemId');
  const location = useLocation();
  // Breadcrumb, dashboard depend on user skip or not
  const breadcrumb = !id
    ? [
        {
          value: 'Assets',
          href: '/assets',
        },
        {value: 'Add Asset', href: '/assets/assetsUpload'},
        {value: 'Add Vehicles Asset'},
      ]
    : [
        {
          value: 'Assets',
          href: '/assets',
        },
        {value: 'Add Asset', href: '/assets/assetsUpload'},
        {value: 'Edit Vehicles Asset'},
      ];

  // Options of select fields
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [legalEntities, setlegalEntities] = useState<SelectOption[]>([]);
  const [properties, setPropertie] = useState<SelectOption[]>([]);
  const [assessors, setAssessors] = useState<SelectOption[]>([]);
  const [assetclasses, setassetclasses] = useState<SelectOption[]>([]);
  const [fetchingCompanies, setFetchingCompanies] = useState(false);
  const [fetchingAllLegalEntities, setFetchingAllLegalEntities] = useState(false);
  const [fetchingAllProperties, setFetchingAllProperties] = useState(false);
  const [fetchingAllAssessors, setFetchingAllAssessors] = useState(false);
  const selectedSystemTaxYear = useSelectedSystemTaxYear();
  
  // Get options of select from JSON
  useEffect(() => {
    setFetchingCompanies(true);
    getCompanies()
      .then((d) => {
        setCompanies(setOptions(d));
        setFetchingCompanies(false);
      })
      .catch(() => {
        setFetchingCompanies(false);
      });

    setFetchingAllLegalEntities(true);
    getAllLegalEntities()
      .then((d) => {
        setlegalEntities(setOptions(d));
        setFetchingAllLegalEntities(false);
      })
      .catch(() => {
        setFetchingAllLegalEntities(false);
      });

    setFetchingAllProperties(true);
    getAllProperties()
      .then((d) => {
        setPropertie(setOptions(d));
        setFetchingAllProperties(false);
      })
      .catch(() => {
        setFetchingAllProperties(false);
      });

    setFetchingAllAssessors(true);
    getAllAssessors(selectedSystemTaxYear?.taxYear || '')
      .then((d) => {
        setAssessors(
          _.map(d.data.items, (item) => ({
            value: item.id,
            label: item.assessor.name,
            name: item.number,
            item: item,
          })),
        );
        setFetchingAllAssessors(false);
      })
      .catch(() => {
        setFetchingAllAssessors(false);
      });
  }, []);

  const navigate = useNavigate();

  // Flag for submit or not
  const [issubmit, setissubmit] = useState<boolean>(false);
  const [submitToast, setSubmitToast] = useState<boolean>(false);
  const [isSaveReset, setIsSaveReset] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedSubmitButton, setSelectedSubmitButton] = useState('');

  function handleError(e: AxiosResponse<IErrorBase>) {
    setToastMessage({
      visible: true,
      message: e?.data?.errors?.length
        ? e?.data.errors[0].replace(/items\.\d\./, '')
        : `Error occurs, please try again later.`,
      type: 'error',
    });
    setSubmitting(false);
  }

  const {taxYear: currentYear} = useDashboardInfo();
  const formik = useFormik<FormikValues>({
    initialValues: newInit,
    enableReinitialize: true,
    validate: (values) => {
      // Validate form,if Same As Mailing Address checkbox is checked,not validate the package fields
      if (issubmit) {
        let errors = validateEmpty(_.pick(values, requiredField));
        if (
          values.stateId &&
          propertyStateId &&
          !_.isEqual(values.stateId, propertyStateId)
        ) {
          errors = _.assign({}, errors, {
            stateId: `Must be the same as Property's state: ${
              _.find(states, ['id', propertyStateId])?.abbreviation
            }`,
          });
        }
        if (
          values.taxYear &&
          !_.isEqual(_.toString(values.taxYear), _.toString(currentYear))
        ) {
          errors = _.assign(errors, {
            taxYear: `The tax year must be ${currentYear}`,
          });
        }
        const result = _.every(_.values(errors), (e: string | []) => {
          return _.every(e, _.isEmpty);
        });
        if (!result) {
          if (submitToast) {
            setSubmitToast(false);
            setToastMessage({
              visible: true,
              message:
                'The Asset you save had some errors that need to be fixed. Please address these to move forward.',
              type: 'error',
            });
          }
          return errors;
        }
      }
    },
    onSubmit: () => {
      setNavBlock(false);
      const result = _.cloneDeep(formik.values);
      _.map(decimalField, (item) => {
        if (!_.isEqual(item, 'vehicle.vehicleYear')) {
          return _.update(result, item, (v) => _.toNumber(v));
        } else {
          return _.update(result, item, (v) => (v ? _.toNumber(v) : v));
        }
      });
      if (id) {
        if (uploadMatch) {
          setSubmitting(true);
          updateUploadFile(id, {
            items: _.unionBy(
              _.concat(
                removeAssetsEmptyFields(
                  _.assign(result, {
                    companyName: _.find(companies, ['value', result.companyId])
                      ?.label,
                    companyNumber: _.find(companies, [
                      'value',
                      result.companyId,
                    ])?.name,
                    legalEntityName: _.find(legalEntities, [
                      'value',
                      result.legalEntityId,
                    ])?.label,
                    legalEntityNumber: _.find(legalEntities, [
                      'value',
                      result.legalEntityId,
                    ])?.name,
                    propertyName: _.find(properties, [
                      'value',
                      result.propertyId,
                    ])?.label,
                    propertyNumber: _.find(properties, [
                      'value',
                      result.propertyId,
                    ])?.name,
                    assessor: _.find(assessors, ['value', result.assessorAccountId])
                      ?.label,
                    assessorAccountNumber: _.find(assessors, [
                      'value',
                      result.assessorAccountId,
                    ])?.name,
                    state: _.find(states, ['id', result.stateId])?.abbreviation,
                  }),
                ),
                items,
              ),
              'id',
            ),
          })
            .then((e: AxiosResponse<IErrorBase>) => {
              if (_.inRange(e.status, 200, 299)) {
                navigate(NAVIGATE);
              } else {
                setToastMessage({
                  visible: true,
                  message: `Error occurs, please try again later.`,
                  type: 'error',
                });
              }
              setSubmitting(false);
            })
            .catch((e) => {
              handleError(e);
            });
        } else {
          setSubmitting(true);
          updateAsset(
            id,
            _.omit(
              removeAssetsEmptyFields(result),
              'legalEntityId',
              'propertyId',
            ),
          )
            .then((e: AxiosResponse<IErrorBase>) => {
              if (_.inRange(e.status, 200, 299)) {
                appDispatch(getDashboardInfo());
                navigate(NAVIGATE);
              } else {
                setToastMessage({
                  visible: true,
                  message: `Error occurs, please try again later.`,
                  type: 'error',
                });
              }
              setSubmitting(false);
            })
            .catch((e) => {
              handleError(e);
            });
        }
      } else {
        setSubmitting(true);
        setAssets({
          items: [
            _.omit(
              removeAssetsEmptyFields(result),
              'legalEntityId',
              'propertyId',
            ),
          ],
        })
          .then((e: AxiosResponse<IErrorBase>) => {
            if (_.inRange(e.status, 200, 299)) {
              if (isSaveReset) {
                formik.resetForm();
                setToastMessage({
                  visible: true,
                  message: `Add Asset successfully`,
                  type: 'success',
                });
                appDispatch(getDashboardInfo());
                setIsSaveReset(false);
                setissubmit(false);
                setSubmitToast(false);
              } else {
                appDispatch(getDashboardInfo());
                navigate(NAVIGATE);
              }
            } else {
              setToastMessage({
                visible: true,
                message: `Error occurs, please try again later.`,
                type: 'error',
              });
            }
            setSubmitting(false);
          })
          .catch((e) => {
            handleError(e);
          });
      }
    },
  });

  let NAVIGATE = '';
  let assetOriginValue = '';
  if (!id) {
    NAVIGATE = `/assets/assetsUpload`;
    assetOriginValue = 'Manually';
  } else {
    if (uploadMatch) {
      NAVIGATE = `/assets/assetsReview/${id}`;
      assetOriginValue = 'Import';
    } else {
      assetOriginValue = formik.values.origin;
      if (location.state) {
        NAVIGATE = location.state as string;
      } else {
        NAVIGATE = `/assets?assetTab=Vehicle`;
      }
    }
  }

  const [debounce] = useDebounce(formik.values.class, 400);
  useEffect(() => {
    getAllAssetClass({page: 1, perPage: 20, text: debounce}).then((d) =>
      setassetclasses(setOptions(d)),
    );
  }, [debounce]);
  // reset valid by click the close icon of error message box
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  //Edit
  const [asset, setAsset] = useState<AssetField>();
  const [items, setItems] = useState<AssetField[]>([]);
  const [fetchingAsset, setFetchingAsset] = useState(false);
  useEffect(() => {
    if (id) {
      if (uploadMatch) {
        setFetchingAsset(true);
        getUploadFile(id)
          .then((d) => {
            setItems(d.data.items);
            const result = _.find(d.data.items, (item) =>
              _.isEqual(_.toString(item['id']), assetId),
            );
            setAsset(_.mapValues(result));
            setPropertyStateId(
              _.get(
                _.find(properties, ['value', result.propertyId]),
                'item.address.stateId',
              ),
            );
            setTimeout(() => {
              setissubmit(true);
            }, 500);

            setFetchingAsset(false);
          })
          .catch(() => {
            setFetchingAsset(false);
          });
      } else {
        setFetchingAsset(true);
        getAsset(id)
          .then((d) => {
            const result = _.assign(d.data, {
              propertyId: d.data.assessorAccount.property?.id,
              legalEntityId: d.data.assessorAccount.property?.legalEntity?.id,
              taxYear: _.toString(d.data.taxYear),
            });
            setAsset(
              _.assign(d.data, {
                propertyId: d.data.assessorAccount.property?.id,
                legalEntityId: d.data.assessorAccount.property?.legalEntity?.id,
                taxYear: _.toString(d.data.taxYear),
              }),
            );
            setPropertyStateId(result.assessorAccount.property.address.stateId);

            setFetchingAsset(false);
          })
          .catch(() => {
            setFetchingAsset(false);
          });
      }
    }
  }, [id, uploadMatch, properties]);

  const [statincorporation, setstatincorporation] = useState<SelectOption[]>([],);
  const [propertyStateId, setPropertyStateId] = useState();
  const [currentSelected, setCurrentSelected] = useState<string>('company');

  useCommonEffects(asset, states, companies, properties, legalEntities, requiredField, formik,
    setstatincorporation, setPropertyStateId, setNavBlock, newInit, currentSelected);

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <Breadcrumb items={breadcrumb} />
        {/* Error message box,if there are errors message */}
        {toastMessage?.visible && (
          <div className={style['toast']}>
            <ToastMessage
              status={toastMessage}
              className={toastMessage?.type ? toastMessage?.type : ''}
            />
          </div>
        )}
        <div className={style['header']}>
          <h2>
            {!id ? (
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                tooltipKey='Add Vehicles Asset'
              >
                <span>Add Vehicles Asset</span>
              </TooltipWrapper>
            ) : (
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageTitle}
                tooltipKey='Edit Vehicles Asset'
              >
                <span>Edit Vehicles Asset</span>
              </TooltipWrapper>
            )}
          </h2>
          <div className={style['addition-disposal-checkboxes']}>
            <CustomCheckbox
              classnames={style['margin-right']}
              label='Addition'
              value={formik.values.isAddition}
              onChange={(value) => {
                formik.setFieldValue(`isAddition`, value);
              }}
            />
            <CustomCheckbox
              label='Disposed'
              value={formik.values.disposed}
              onChange={(value) => {
                formik.setFieldValue(`disposed`, value);
              }}
            />
          </div>
        </div>
        <form className={style['main']} onSubmit={formik.handleSubmit}>
          <div className={classNames(style['asset-section'])}>
            <div className={style['section']}>
              {/* Company form list */}
              <div className={style['form']}>
                <fieldset>
                  <div className={style['row']}>
                    <div className={style['column']}>
                      <FieldSelect
                        labelText='Company Name'
                        selectId={formik.values.companyId}
                        options={companies}
                        onSelect={(value) => {
                          formik.setFieldValue(
                            `companyId`,
                            value?.value ? _.toNumber(value?.value) : null,
                          );
                          setCurrentSelected('company');
                        }}
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).companyId
                        }
                        required
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldRelatedSelect
                        value={formik.values.legalEntityId}
                        labelText='Legal Entity Name & Number'
                        options={legalEntities}
                        onChange={(legalEntityId) => {
                          formik.setFieldValue('legalEntityId', legalEntityId);
                          setCurrentSelected('owner');
                        }}
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).legalEntityId
                        }
                        required
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldRelatedSelect
                        labelText='Property Name & Number'
                        value={formik.values.propertyId}
                        options={
                          formik.values.legalEntityId
                            ? _.filter(properties, property => property?.item?.legalEntityId == formik.values.legalEntityId)
                            : []
                        }
                        onChange={(propertyId) => {
                          formik.setFieldValue('propertyId', propertyId);
                          setCurrentSelected('property');
                        }}
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).propertyId
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className={style['row']}>
                    <div className={style['column']}>
                      <FieldRelatedSelect
                        labelText='Assessor & Assessor Account Number'
                        value={formik.values.assessorAccountId}
                        options={_.filter(assessors, assessor => assessor?.item?.propertyId == formik.values.propertyId)}
                        onChange={(assessorAccountId) => {
                          formik.setFieldValue('assessorAccountId', _.toNumber(assessorAccountId));
                          setCurrentSelected('assessor');
                        }}
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).assessorAccountId
                        }
                        required
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldInputWithOptions
                        labelText='Asset Class'
                        options={assetclasses}
                        value={formik.values.class}
                        onChange={(value) =>
                          formik.setFieldValue(`class`, value)
                        }
                        error={
                          formik.errors && (formik.errors as FormikValues).class
                        }
                        placeholder='Enter'
                        required
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldInput
                        labelText='Asset Number'
                        value={formik.values.number}
                        onChange={(value) =>
                          formik.setFieldValue(`number`, value)
                        }
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).number
                        }
                        placeholder='Enter'
                        required
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className={style['row']}>
                    <div className={style['column']}>
                      <FieldInput
                        labelText='Vehicle Make'
                        value={formik.values.vehicle?.vehicleMake}
                        onChange={(value) =>
                          formik.setFieldValue(`vehicle.vehicleMake`, value)
                        }
                        placeholder='Enter'
                        recommended
                        maxLength={50}
                      />
                    </div>
                    <div className={style['column']}>
                      <FieldInput
                        labelText='Vehicle Model'
                        value={formik.values.vehicle?.vehicleModel}
                        onChange={(value) =>
                          formik.setFieldValue(`vehicle.vehicleModel`, value)
                        }
                        placeholder='Enter'
                        recommended
                        maxLength={50}
                      />
                    </div>
                    <div
                      className={classNames(
                        style['column'],
                        style['small-column'],
                      )}
                    >
                      <FieldInput
                        labelText='Vehicle VIN'
                        value={formik.values.vehicle?.vehicleVin}
                        onChange={(value) =>
                          formik.setFieldValue(`vehicle.vehicleVin`, value)
                        }
                        placeholder='Enter'
                        recommended
                        maxLength={50}
                      />
                    </div>
                    <div
                      className={classNames(
                        style['column'],
                        style['small-column'],
                      )}
                    >
                      <FieldInput
                        labelText='Vehicle Year'
                        value={formik.values.vehicle?.vehicleYear}
                        onChange={(value) => {
                          formik.setFieldValue(`vehicle.vehicleYear`, value);
                        }}
                        yearFormat
                        placeholder='Enter'
                        recommended
                        maxLength={4}
                      />
                    </div>
                  </div>
                  <div className={style['row']}>
                    <div className={classNames(style['column'])}>
                      <FieldSelect
                        labelText='State'
                        options={statincorporation}
                        selectId={formik.values.stateId}
                        onSelect={(value) =>
                          formik.setFieldValue(
                            `stateId`,
                            value?.value ? _.toNumber(value?.value) : null,
                          )
                        }
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).stateId
                        }
                        required
                      />
                    </div>
                    <div className={classNames(style['column'])}>
                      <FieldInput
                        labelText='Tax Year'
                        value={formik.values?.taxYear}
                        onChange={(value) =>
                          formik.setFieldValue(`taxYear`, value)
                        }
                        placeholder='Enter'
                        yearFormat
                        required
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).taxYear
                        }
                        maxLength={4}
                      />
                    </div>
                    <div className={classNames(style['column'])}>
                      <FieldDate
                        labelText='Asset Acquisition Date'
                        selectValue={formik.values?.acquisitionDate}
                        onSelect={(value) =>
                          formik.setFieldValue(`acquisitionDate`, value)
                        }
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues).acquisitionDate
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className={style['row']}>
                    <div className={classNames(style['column'])}>
                      <FieldInput
                        labelText='Original Cost'
                        value={formik.values.vehicle?.originalCost}
                        onChange={(value) =>
                          formik.setFieldValue(`vehicle.originalCost`, value)
                        }
                        error={
                          formik.errors &&
                          (formik.errors as FormikValues)?.vehicle?.originalCost
                        }
                        decimal
                        required
                        maxLength={20}
                      />
                    </div>
                    <div
                      className={classNames(
                        style['column'],
                        style['large-column'],
                      )}
                    >
                      <FieldInput
                        labelText='Asset Description'
                        value={formik.values?.description}
                        onChange={(value) =>
                          formik.setFieldValue(`description`, value)
                        }
                        placeholder='Enter'
                        recommended
                        maxLength={100}
                      />
                    </div>
                  </div>
                </fieldset>
                {formik.values.showOptional && (
                  <fieldset>
                    <legend>
                      <TooltipWrapper
                        tooltipSection={TOOLTIP_SECTIONS.Common}
                        tooltipKey='Optional Information'
                      >
                        <span>Optional Information</span>
                      </TooltipWrapper>
                    </legend>
                    <div className={style['row']}>
                      <div
                        className={classNames(
                          style['column'],
                          style['half-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Alternate Asset ID'
                          value={formik.values?.alternateAssetId}
                          onChange={(value) =>
                            formik.setFieldValue(`alternateAssetId`, value)
                          }
                          placeholder='Enter'
                          maxLength={50}
                        />
                      </div>
                      <div
                        className={classNames(
                          style['column'],
                          style['half-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Asset GL Code / Alternate Account Number'
                          value={
                            formik.values.vehicle?.glCodeAlternateAccountNumber
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `vehicle.glCodeAlternateAccountNumber`,
                              value,
                            )
                          }
                          placeholder='Enter'
                          maxLength={50}
                        />
                      </div>
                    </div>
                    <div className={style['row']}>
                      <div
                        className={classNames(
                          style['column'],
                          style['life-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Asset Life'
                          value={formik.values.vehicle?.life}
                          onChange={(value) =>
                            formik.setFieldValue(`vehicle.life`, value)
                          }
                          placeholder='Enter'
                          maxLength={4}
                        />
                      </div>
                      <div
                        className={classNames(
                          style['column'],
                          style['address-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Company Cost Center'
                          value={formik.values.vehicle?.companyCostCenter}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `vehicle.companyCostCenter`,
                              value,
                            )
                          }
                          placeholder='Enter'
                          maxLength={50}
                        />
                      </div>
                      <div
                        className={classNames(
                          style['column'],
                          style['half-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Historical Asset Number'
                          value={formik.values.vehicle?.historicalNumber}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `vehicle.historicalNumber`,
                              value,
                            )
                          }
                          placeholder='Enter'
                          maxLength={50}
                        />
                      </div>
                    </div>
                    <div className={style['row']}>
                      <div
                        className={classNames(
                          style['column'],
                          style['eq-column'],
                        )}
                      >
                        <FieldInput
                          labelText='LTD Depreciation'
                          value={formik.values.vehicle?.ltdDepreciation}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `vehicle.ltdDepreciation`,
                              value,
                            )
                          }
                          decimal
                          maxLength={20}
                        />
                      </div>
                      <div
                        className={classNames(
                          style['column'],
                          style['eq-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Asset Rebook Cost'
                          value={formik.values.vehicle?.rebookCost}
                          onChange={(value) =>
                            formik.setFieldValue(`vehicle.rebookCost`, value)
                          }
                          decimal
                          maxLength={20}
                        />
                      </div>
                      <div
                        className={classNames(
                          style['column'],
                          style['eq-column'],
                        )}
                      >
                        <FieldDate
                          labelText='Asset Rebook Date'
                          selectValue={formik.values.vehicle?.rebookDate}
                          onSelect={(value) =>
                            formik.setFieldValue(`vehicle.rebookDate`, value)
                          }
                        />
                      </div>
                      <div
                        className={classNames(
                          style['column'],
                          style['eq-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Rendered Market Value'
                          value={formik.values.vehicle?.renderedMarketValue}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `vehicle.renderedMarketValue`,
                              value,
                            )
                          }
                          decimal
                          maxLength={20}
                        />
                      </div>
                    </div>
                    <div className={style['row']}>
                      <div
                        className={classNames(
                          style['column'],
                          style['larger-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Notes'
                          value={formik.values?.notes}
                          onChange={(value) =>
                            formik.setFieldValue(`notes`, value)
                          }
                          placeholder='Enter'
                          maxLength={255}
                        />
                      </div>
                      <div
                        className={classNames(
                          style['column'],
                          style['small-column'],
                        )}
                      >
                        <FieldInput
                          labelText='Asset origin'
                          value={assetOriginValue}
                          onChange={(value) =>
                            formik.setFieldValue(`origin`, value)
                          }
                          placeholder='Enter'
                          maxLength={20}
                          disabled
                        />
                      </div>
                    </div>
                  </fieldset>
                )}
                <div className={style['bottom-bar']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.PageAction}
                    tooltipKey='Show/Hide more optional fields'
                  >
                    <i
                      role='button'
                      className={classNames('link', style['toggle-link'])}
                      onClick={() =>
                        formik.setFieldValue(
                          `showOptional`,
                          !formik.values.showOptional,
                        )
                      }
                    >
                      {`${
                        formik.values.showOptional ? 'Hide' : 'Show'
                      } more optional fields`}
                    </i>
                  </TooltipWrapper>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey='Required field'
                  >
                    <span className={style['required']}>* Required field</span>
                  </TooltipWrapper>
                </div>
              </div>
            </div>
            {/* Add company link */}
          </div>
          <div className={style['footer']}>
            {/* Submit link,it change the flag of submit */}
            <div className={style['buttons']}>
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Save and complete'
              >
                <button
                  type='submit'
                  className='primary'
                  onClick={() => {
                    setSelectedSubmitButton('Save and complete');
                    setissubmit(true);
                    setSubmitToast(true);
                  }}
                  disabled={submitting}
                >
                  Save and complete
                  {submitting &&
                  selectedSubmitButton === 'Save and complete' ? (
                    <LoaderIndicator
                      className='button-loading'
                      loading={true}
                    />
                  ) : null}
                </button>
              </TooltipWrapper>
              {!id && (
                <TooltipWrapper
                  tooltipSection={TOOLTIP_SECTIONS.PageAction}
                  tooltipKey='Save and add another asset'
                >
                  <button
                    type='submit'
                    className='primary'
                    onClick={() => {
                      setSelectedSubmitButton('Save and add another asset');
                      setissubmit(true);
                      setSubmitToast(true);
                      setIsSaveReset(true);
                    }}
                    disabled={submitting}
                  >
                    Save and add another asset
                    {submitting &&
                    selectedSubmitButton === 'Save and add another asset' ? (
                      <LoaderIndicator
                        className='button-loading'
                        loading={true}
                      />
                    ) : null}
                  </button>
                </TooltipWrapper>
              )}
              <TooltipWrapper
                tooltipSection={TOOLTIP_SECTIONS.PageAction}
                tooltipKey='Cancel'
              >
                <button
                  type='button'
                  className='button secondary'
                  onClick={() => navigate(NAVIGATE)}
                  disabled={submitting}
                >
                  Cancel
                </button>
              </TooltipWrapper>
            </div>
          </div>
        </form>
      </div>
      <DiscardChangeConfirmModal
        initialData={{}}
        inputData={{}}
        navBlock={navBlock}
      />

      <Loader
        isOpen={
          fetchingCompanies ||
          fetchingAllLegalEntities ||
          fetchingAllProperties ||
          fetchingAllAssessors ||
          fetchingAsset
        }
      />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  states: state.states,
});

export default connect(mapStateToProps)(VehicleSetup);
