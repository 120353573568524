import React, {
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { PageTooltip } from "../../interface";
import { RootState } from "../../redux/store";
import { useLocation } from "react-router-dom";
import { PAGES_NAMES_MAPPING } from "../../constants";
import { TOOLTIP_SECTIONS } from "../../enums";
import { isNil } from "lodash";
import { STooltip } from "@avalara/skylab-sdk/react";

export interface Props {
  children: React.ReactElement;
  tooltipSection: TOOLTIP_SECTIONS;
  tooltipKey: string;
  className?: string;
}

interface TooltipProps {
  id: string;
  triggerId: string;
  content: string;
}

let TooltipCounter = 0;

const MemoizedTooltip = React.memo(function MemoizedTooltip({
  id,
  triggerId,
  content,
}: TooltipProps) {
  return (
    <STooltip tooltipId={id} triggerId={triggerId}>
      <span>{content}</span>
    </STooltip>
  );
});

function TooltipWrapper({
  children,
  tooltipSection,
  tooltipKey,
  className,
}: Props) {
  const [ids] = useState(() => {
    const id = TooltipCounter++;
    return {
      trigger: `tooltip-trigger-${id}`,
      tooltip: `tooltip-${id}`,
    };
  });

  const tooltips: PageTooltip[] = useSelector(
    (state: RootState) => state.admin.tooltips
  );

  const location = useLocation();

  const commonTooltip: PageTooltip | undefined = useMemo(() => {
    return tooltips.find((item) => item.page === "Common Page");
  }, [tooltips]);

  const pageTooltip: PageTooltip | undefined = useMemo(() => {
    let pageName = "";
    for (const [key, value] of Object.entries(PAGES_NAMES_MAPPING)) {
      if (new RegExp("^" + value + "$").test(location.pathname)) {
        pageName = key;
        break;
      }
      if (location.search && new RegExp(/assetTab=/).test(location.search)) {
        if (
          new RegExp("^" + value + "$").test(
            location.pathname + location.search
          )
        ) {
          pageName = key;
          break;
        }
      }
    }

    if (pageName) {
      return tooltips.find((item) => item.page === pageName);
    }

    return undefined;
  }, [tooltips, location.pathname, location.search]);

  const selectedTooltip = useMemo(
    () =>
      pageTooltip?.items.find(
        (x) => x.section === tooltipSection && x.keyword === tooltipKey && x.enabled
      ) ??
      commonTooltip?.items.find(
        (x) => x.section === tooltipSection && x.keyword === tooltipKey && x.enabled
      ) ??
      null,
    [tooltipSection, tooltipKey, pageTooltip, commonTooltip]
  );

  const hideForOverlays = useCallback(
    (e: MouseEvent) => {
      const overlay = (e.nativeEvent.target as HTMLElement)?.closest?.(
        "s-dropdown[open]" // Only open dropdowns for now
      );
      const tooltip = document.getElementById(ids.tooltip);
      tooltip && (tooltip.style.opacity = isNil(overlay) ? "1" : "0");
    },
    [ids]
  );

  return (
    <div
      id={ids.trigger}
      className={className}
      style={{ display: "inline-block" }}
      onMouseOver={hideForOverlays}
      onClick={hideForOverlays}
    >
      {children}

      {selectedTooltip?.description && (
        <MemoizedTooltip
          id={ids.tooltip}
          triggerId={ids.trigger}
          content={selectedTooltip?.description}
        />
      )}
    </div>
  );
}

export default TooltipWrapper;
